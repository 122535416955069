import { Redirect, Router } from '@reach/router';
import * as React from 'react';
import AboutUs from './pages/AboutUs';
import BestOfOnyxPage from './pages/BestOfOnyx';
import CaseStudy from './pages/CaseStudy';
import IndexPage from './pages/IndexPage';
import Services_SocMed from './pages/Services_SocMed';
import Marketing_Tech from './pages/Services_MarTech';
import TalkToUs from './pages/TalkToUs';


const RootApp = () => (
	<Router primary={false}>
		<IndexPage path='/' />
		<AboutUs path='/about-us' />
		<Redirect from='/about-onyx' to='/about-us' />

		<TalkToUs path='/contact-us' />
		<Redirect from='/talk-to-us' to='/contact-us' />

		<CaseStudy path='/case-study/:slug' />
		<BestOfOnyxPage path='/case-studies' />

		<Services_SocMed path='/services/social-media-marketing' />
		<Redirect from='/services/social-media' to='/services/social-media-marketing' />
		
		<Marketing_Tech path='/services/marketing-technology' />
		<Redirect from='/services/social-media' to='/services/marketing-technology' />
		
		<Redirect from='/best-of-onyx' to='/case-studies' />
		<Redirect default from='/*' to='/' />
	</Router>
);

export default RootApp;
