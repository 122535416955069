import { useEffect, useState } from "react";

export default function useMinWidth(width: number) {
	const [isGte, setIsGte] = useState(false);

	useEffect(() => {
		const handler = () => {
			setIsGte(window.innerWidth >= width);
		};

		handler();

		window.addEventListener('resize', handler);
		return () => window.removeEventListener('resize', handler);
	}, [width]);

	return isGte;
}