import * as React from 'react';
import cx from '../utilities/cx';

const FillButton: React.FC<React.ComponentPropsWithoutRef<'button'> & { wrapperClassName?: string; }> = ({ wrapperClassName, ...props }) => {
	const [hover, setHover] = React.useState(false);

	return (
		<div className={cx('relative', wrapperClassName)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
			<button {...props} className={cx('unhover', props.className)} />
			<div className={cx('absolute inset-0 transition-all duration-300 clip-r', hover && 'unclip')}>
				<button {...props} className={cx('hover', props.className)} />
			</div>
		</div>
	);
};

export default FillButton;