/**
 * Use to compose classes conditionally, filters out all non-strings.  
 * E.g. ```
 * <button className={cx("btn btn-primary", active && "btn-active")}>
 *   Click me
 * </button>
 * ```
 * @param classes classnames and conditionals
 * @returns {string} string of whitespace-separated classnames
 */
const cx = (...classes: any[]): string => classes
	.filter((part): part is string => typeof part === 'string')
	.join(' ')
	.replace(/\s+/g, ' ')
	.trim();

export default cx;