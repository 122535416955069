import * as React from 'react';
import cx from '../utilities/cx';
import Markdown from './Markdown';

type TextWithQuoteProps = {
  className?: string;
  header: string;
  contentNode?: React.ReactNode;
  content?: string;
  alignRight?: boolean;
};

const TextWithQuote: React.FC<TextWithQuoteProps> = ({ className, header, content, contentNode: contentRender, alignRight }) => (
  <section className={cx('w-full flex flex-wrap justify-center', className)}>
    <header className={cx('lg:w-3/12 sm:w-full lg:pr-4 sm:text-center', alignRight ? 'text-left lg:order-2 lg:ml-8' : 'text-right lg:mr-8')}>
      <h3>{header}</h3>
    </header>
    <div className={cx('lg:w-7/12 sm:w-full lg:pl-4', alignRight && 'lg:order-1')}>
      {content != null ? <Markdown source={content} /> : contentRender}

    </div>
  </section>
);

export default TextWithQuote;