import ApolloClient, { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-boost';
import * as introspectionQueryResultData from '../../__generated__/fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher }).restore((window as any).__APOLLO_STATE__);
const connectClient = (strapiURL: string) => new ApolloClient({
	uri: `${strapiURL}/graphql`, cache,
});

export default connectClient;