import * as React from 'react';
import Markdown from './Markdown';
import cx from '../utilities/cx';

interface TextWithImageProps {
  className?: string,
  imageUrl: string;
  imageAlt: string;
  text: string,
  imageRight: boolean;
}

const TextWithImage: React.FC<TextWithImageProps> = ({ className, imageAlt, imageUrl, text, imageRight }) => {
  return (
    <section className={cx('flex flex-wrap w-full mb-section -mx-2', imageRight ? 'flex-row-reverse' : 'flex-row', className)}>
      <div className='lg:w-1/2 sm:w-full px-2'>
        <img className='w-full h-auto' src={imageUrl} alt={imageAlt} />
      </div>
      <article className='lg:w-1/2 sm:w-full px-2 flex flex-col justify-center'>
        <Markdown source={text} />
      </article>
    </section>
  );
};

export default TextWithImage;