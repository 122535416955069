import { AllArticleQuery_articles_tags } from "../../__generated__/AllArticleQuery";

export interface Tag extends AllArticleQuery_articles_tags {
  label?: string;
}

const isSocial = (tags: (Tag | null)[], title: string = ''): boolean => {
  return tags.some((tag) => tag?.name ?? tag?.label === "Social") && title.toLowerCase().includes('social media');
};

export default isSocial;
