import * as React from "react";
import { hydrate, render } from "react-dom";
import RootApp from "./RootApp";
import { ApolloProvider } from "@apollo/react-hooks";
import connectClient from "./utilities/apollo";
import StrapiURLContext from "./utilities/strapiURL";

const hydrateOrRender = document.getElementById('root')?.hasChildNodes() ? hydrate : render;

//const strapiURL = `${location.protocol}//${location.hostname}:1337`;
//const strapiURL = 'https://api.www.onyxisland.agency';
const strapiURL = 'https://www.onyxisland.agency/public';

const client = connectClient(strapiURL);

hydrateOrRender((
	<StrapiURLContext.Provider value={strapiURL}>
		<ApolloProvider client={client}>
			<RootApp />
		</ApolloProvider>
	</StrapiURLContext.Provider>
), document.getElementById('root'));