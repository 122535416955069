import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import Helmet from "react-helmet";
import Slider from "react-slick";
import Footer from "../components/Footer";
import GoToTopButton from "../components/GoToTopButton";
import { Menu } from "../components/Menu";
import ParallaxTile from "../components/ParallaxTile";
import ScrollBar from "../components/ScrollBar";
import StickyHeader from "../components/StickyHeader";
import { OurServices } from "../containers/OurServices";
import config from "../utilities/config";
import useMeasure from "../utilities/useMeasure";

import AnimatedMontauk from "../components/AnimatedMontauk";

const AboutText = React.forwardRef<
  HTMLDivElement,
  { className?: string; titleClassName?: string }
>(({ className, titleClassName }, ref) => (
  <div ref={ref} className={className}>
    <h3 className={titleClassName}>About Onyx Island</h3>
    <p>
      Founded in 2010, Onyx Island is headquartered in Singapore with regional
      operations in Bangladesh, Indonesia and Malaysia. Our team's collective
      expertise in Digitalisation, Digital Marketing, Social Media Marketing
      &amp; Management, UI/UX Design, Website &amp; CMS Development, HubSpot CRM
      Implementation &amp; Integration, Marketing &amp; Sales Automation and
      Data Analytics allow us to consult and help our clients solve their
      marketing, communications, digital and operational challenges.
    </p>
    <p>
      At Onyx Island, we put user experience at the heart of everything we do.
      As consumers ourselves, we expect to receive a delightful customer
      experience from the Brands which we purchase products or services from.
    </p>
    <p>
      Our user-first approach strives to craft seamless and meaningful brand
      experiences across multiple digital marketing touchpoints, be it campaign
      landing pages, brand websites, online advertising banners, electronic
      direct mailers, social media content etc. This results in consistent
      communication and a positive user experience for your Brand, fostering
      lasting and valuable relationships with your customers.
    </p>
    <p>Our belief is to deliver a meaningful experience. Every time.</p>
  </div>
));

const partnerImages: { src: string; alt: string }[] = [
  {
    src: require("../../assets/partner-logo/hubspot-diamond-horizontal-white.png"),
    alt: "HubSpot Solutions Partner Program: Diamond Partner",
  },
  {
    src: require("../../assets/partner-logo/BigCommerce-logo-dark.svg"),
    alt: "BigCommerce",
  },
  {
    src: require("../../assets/partner-logo/Wechat-network-logo.svg"),
    alt: "WeChat Network",
  },
];

const clientImages: { src: string; alt: string }[] = [
  {
    src: require("../../assets/clients-new/clientlogo-assurity.svg"),
    alt: "Assurity",
  },
  {
    src: require("../../assets/clients-new/clientlogo-beamsuntory.svg"),
    alt: "Beam Suntory",
  },
  {
    src: require("../../assets/clients-new/clientlogo-bioessence.png"),
    alt: "Bio-essence",
  },
  {
    src: require("../../assets/clients-new/clientlogo-charlesmonat.svg"),
    alt: "Charles Monat",
  },
  {
    src: require("../../assets/clients-new/clientlogo-cortina.png"),
    alt: "Cortina Watch",
  },
  {
    src: require("../../assets/clients-new/clientlogo-dermalab.svg"),
    alt: "Derma Lab",
  },
  {
    src: require("../../assets/clients-new/clientlogo-experian.svg"),
    alt: "Experian",
  },
  { src: require("../../assets/clients-new/clientlogo-fia.svg"), alt: "FIA" },
  {
    src: require("../../assets/clients-new/clientlogo-jiggernpony.png"),
    alt: "Jigger and Pony",
  },
  {
    src: require("../../assets/clients-new/clientlogo-natgeo.png"),
    alt: "National Geographic",
  },
  { src: require("../../assets/clients-new/clientlogo-nus.svg"), alt: "NUS" },
  {
    src: require("../../assets/clients-new/clientlogo-oakley.png"),
    alt: "Oakley",
  },
  { src: require("../../assets/clients-new/clientlogo-pcc.svg"), alt: "PCC" },
  {
    src: require("../../assets/clients-new/clientlogo-salesforce.svg"),
    alt: "Salesforce",
  },
  {
    src: require("../../assets/clients-new/clientlogo-samsung.svg"),
    alt: "Samsung",
  },
  {
    src: require("../../assets/clients-new/clientlogo-singtel.svg"),
    alt: "Singtel",
  },
  {
    src: require("../../assets/clients-new/clientlogo-spectaclehut.svg"),
    alt: "Spectacle Hut",
  },
  {
    src: require("../../assets/clients-new/clientlogo-starhub.svg"),
    alt: "StarHub",
  },
  {
    src: require("../../assets/clients-new/clientlogo-sunglasshut.svg"),
    alt: "Sunglass Hut",
  },
  {
    src: require("../../assets/clients-new/clientlogo-wacoal.svg"),
    alt: "Wacoal",
  },
];

const AboutUs: React.FC<RouteComponentProps> = () => {
  const [contentElem, setContentElem] = React.useState<
    HTMLElement | undefined
  >();

  const metaTitle = `About Us${config.title_separator}${config.post_title}`;
  const metaDescription = config.site_description;
  const metaImage = config.meta_image;
  const [textMeasure, { height: textHeight }] = useMeasure<HTMLDivElement>();

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content={metaImage} />
      </Helmet>

      <StickyHeader />
      <ScrollBar boundary={contentElem} />
      <Menu />

      <div ref={(e) => setContentElem(e ?? undefined)} className="main">
        <header className="lg:h-screen w-full py-32 flex flex-col items-center justify-center text-center relative">
          <AnimatedMontauk className="absolute inset-y-0 w-screen" />
          <div className="content">
            <h1 className="mb-3 lg:h2 relative">
              We are a Digital Experience Agency.
            </h1>
          </div>
        </header>

        <div className="content">
          <section
            className="w-full mb-section sm:hidden flex items-center"
            style={{ height: `${textHeight}px` }}
          >
            <ParallaxTile
              overrideActive
              className="w-full"
              imageClassName="w-1/2 ratio-1/1"
              textClassName="formatted-text w-3/5"
              imageUrl={require("../../assets/img-aboutus.png")}
            >
              {() => <AboutText ref={textMeasure.ref} />}
            </ParallaxTile>
          </section>

          <section className="lg:hidden mb-section formatted-text">
            <AboutText titleClassName="mb-3" />
            <img
              className="w-full opacity-50"
              src={require("../../assets/img-aboutus.png")}
            />
          </section>

          {/* <OurServices className="mb-section" /> */}


          <section className='w-full max-w-screen-lg mb-section'>
						<header>
							<h3 className='text-center'>OUR SERVICES</h3>
						</header>
						<div className='flex flex-wrap w-full'>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/digital-transformation-and-consultancy.svg')} />
								</div>
								<p className='leading-6'>Digital transformation and consultancy</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/strategy-and-planning.svg')} />
								</div>
								<p className='leading-6'>Strategy and planning</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/ui-ux-design.svg')} />
								</div>
								<p className='leading-6'>User experience / User interface (UX/UI) design</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/information-architecture.svg')} />
								</div>
								<p className='leading-6'>Information architecture (IA)</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/digital-and-social-media-marketing.svg')} />
								</div>
								<p className='leading-6'>Digital and social media marketing</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/creative-ideation.svg')} />
								</div>
								<p className='leading-6'>Creative ideation</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/content-creation-and-production.svg')} />
								</div>
								<p className='leading-6'>Content creation and production</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/crm-implementation-and-integration.svg')} />
								</div>
								<p className='leading-6'>CRM implementation and integration</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/web-operations-and-maintenance-support.svg')} />
								</div>
								<p className='leading-6'>Web operations and maintenance support</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/data-analytics-and-reporting.svg')} />
								</div>
								<p className='leading-6'>Data analytics and reporting</p>
							</div>
						</div>
					</section>


          <section className="flex flex-col lg:w-11/12 sm:w-full mb-section">
            <article className="w-full flex flex-col justify-center formatted-text lg:text-center mb-4">
              <h3>Our Clients</h3>
              <p>
                As a dynamic and progressive company we have established an
                excellent track record with clients and MNCs.
              </p>
              <p>
                In fact, it is our vision and belief that working with such a
                diverse roster, allows us to hone our skills and develop
                best-practices, and then apply them to the benefit of each and
                every one of our clients.
              </p>
            </article>

            <div className="w-full flex flex-wrap justify-center sm:hidden">
              {clientImages.map(({ src, alt }) => (
                <div className="block text-center w-1/5 px-2">
                  <img
                    src={src}
                    alt={alt}
                    title={alt}
                    className="w-full h-full object-contain object-center"
                  />
                </div>
              ))}
            </div>

            <div className="w-full lg:hidden">
              <Slider
                dots
                slidesPerRow={2}
                rows={2}
                prevArrow={<></>}
                nextArrow={<></>}
              >
                {clientImages.map(({ src, alt }) => (
                  <div className="block text-center relative ratio-1/1 p-2 pointer-events-none">
                    <img
                      src={src}
                      alt={alt}
                      title={alt}
                      className="absolute inset-0 w-full h-full object-contain object-center"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </section>

          <section className="flex flex-wrap lg:w-11/12 sm:w-full mb-section">
            <article className="lg:w-1/2 sm:w-full lg:px-4 flex flex-col justify-center formatted-text">
              <h3>Our Strategic Partners</h3>
              <p>
                Apart from our core focus in producing results to fulfil our
                clients' goals, it is imperative that we work alongside with our
                strategic partners to deliver effective marketing and
                technological solutions ensuring meaningful experiences to
                consumers.
              </p>
            </article>
            <div className="lg:w-1/2 sm:w-full lg:px-4 sm:pt-8 flex flex-col items-center justify-between">
              {partnerImages.map(({ src, alt }) => (
                <div className="block text-center lg:w-1/3 sm:w-2/3 sm:py-6">
                  <img
                    src={src}
                    alt={alt}
                    title={alt}
                    className="w-full h-full object-contain object-center"
                  />
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>

      <GoToTopButton boundary={contentElem} />
      <Footer />
    </>
  );
};

export default AboutUs;
