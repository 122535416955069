import * as React from "react";
import cx from "../utilities/cx";
import FillButton from "./FillButton";

type PackageHeaderProps = {
	recom?: boolean;
	packageName: React.ReactNode;
	price: React.ReactNode;
	postPrice?: React.ReactNode;
	CTA?: React.ReactNode;
};

export default function PackageHeader(props: PackageHeaderProps) {
	return (
		<>
			<div>
				<div className={cx(props.recom || "invisible", "w-full bg-onyx-green font-header text-sm text-onyx-white text-center uppercase py-1")}>
					Recommended!
				</div>
				<div className={cx("border bg-opacity-5 px-4 py-8", props.recom ? "border-onyx-green bg-onyx-green" : "border-white bg-white")}>
					<div className="text-xl mb-2">{props.packageName}</div>
					<div className="flex items-start mb-2">
						<div className="font-header text-2xl mr-2">SGD</div>
						<div className="font-header text-5xl">{props.price}</div>
					</div>
					<div className="mb-4">{props.postPrice ?? "per month"}</div>
					{props.CTA ?? (
						<a href="#contact-us">
							<FillButton className={cx(props.recom ? "primary" : "dull", "smaller w-full min-w-0")}>Contact Us</FillButton>
						</a>
					)}
				</div>
			</div>
		</>
	);
}
