import * as React from 'react';
import Dropdown from './Dropdown';

interface GlueDropdownProps {
	className?: string;
	selectElem: HTMLSelectElement;
}

const GlueDropdown: React.FC<GlueDropdownProps> = ({ className, selectElem }) => {
	const [value, setValue] = React.useState(() => selectElem.value);
	const [placeholder, setPlaceholder] = React.useState('');
	const [options] = React.useState(() => {
		const options: { [value: string]: string; } = {};
		Array.from(selectElem.options).forEach(elem => {
			if (elem.value.length > 0) {
				options[elem.value] = elem.innerText;
			} else {
				setPlaceholder(elem.innerText);
			}
		});

		return options;
	});

	const onChange = (value: string) => {
		selectElem.value = value;
		setValue(value);
	};

	return (
		<Dropdown {...{ className, placeholder, options, value, onChange }} />
	);
};

export default GlueDropdown;