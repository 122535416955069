import * as React from 'react';

// const canUseDOM = typeof location !== 'undefined';

// const strapiURL = (req?: import('express').Request) => canUseDOM
// 	? `${location.protocol}//${location.hostname}:1337`
// 	: `${req!.protocol}://${req!.hostname}:1337`
// 	;

const StrapiURLContext = React.createContext('');


export default StrapiURLContext;

