import * as React from 'react';
import cx from '../utilities/cx';

const ScrollBar: React.FC<{ boundary?: HTMLElement | null; }> = ({ boundary }) => {
	const [progress, setProgress] = React.useState<number | null>(null);
	const [bottom, setBottom] = React.useState(0);

	React.useEffect(() => {
		const scrollListener = () => {
			const scrollY = window.pageYOffset;
			const scrollHeight = document.body.clientHeight - window.innerHeight;

			setProgress(Math.min(100, (scrollY / scrollHeight) * 100));

			// if (boundary != null) {
			// 	const boundaryInfo = boundary.getClientRects()[0];

			// 	const boundaryBottomY = boundaryInfo.y + boundaryInfo.height;
			// 	console.log(boundaryBottomY);
			// 	const bottomMargin = Math.max(0, window.innerHeight - boundaryBottomY);


			// 	setBottom(bottomMargin);
			// } else {
			// 	setBottom(0);
			// }
		};

		scrollListener();

		window.addEventListener('scroll', scrollListener);
		return () => window.removeEventListener('scroll', scrollListener);
	}, [boundary]);

	return (
		<>
			<div className='sm:hidden fixed bottom-0 left-0 z-40 h-screen ml-6 flex items-center' style={{ width: 1, bottom: `${bottom}px` }}>
				<div className='flex-1 bg-onyx-dark-gray relative' style={{ height: '30%' }}>
					<div
						className={cx('bg-onyx-green h-8 absolute inset-x-0 transition-opacity duration-300', progress == null && 'opacity-0')}
						style={{ top: `${progress ?? 0}%`, transform: `translateY(-${progress ?? 0}%)` }}
					/>
				</div>
			</div>
		</>
	);
};

export default ScrollBar;