import { useQuery } from "@apollo/react-hooks";
import { Link, RouteComponentProps } from "@reach/router";
import { gql } from "apollo-boost";
import { useContext, useState, useRef, useLayoutEffect } from "react";
import Helmet from "react-helmet";
import { MarTecFeaturedQuery } from "../../__generated__/MarTecFeaturedQuery";
import Anchor from "../components/Anchor";
import Footer from "../components/Footer";
import GoToTopButton from "../components/GoToTopButton";
import { Menu } from "../components/Menu";
import ParallaxTile from "../components/ParallaxTile";
import ScrollBar from "../components/ScrollBar";
import Services_MarTechForm from "../components/Services_MarTechForm";
import StickyHeader from "../components/StickyHeader";
import TextHoverFill from "../components/TextHoverFill";
import TextWithQuote from "../components/TextWithQuote";
import config from "../utilities/config";
import StrapiURLContext from "../utilities/strapiURL";
import useMinWidth from "../utilities/useMinWidth";

const MAR_TEC_FEATURED = gql`
  query MarTecFeaturedQuery {
    servicesMarketingTechnology {
      case_study_1 {
        id
        title
        slug
        heroImage {
          url
        }
        tags {
          id
          name
        }
      }
      case_study_2 {
        id
        title
        slug
        heroImage {
          url
        }
        tags {
          id
          name
        }
      }
    }
  }
`;
export default function Services_MarTec({}: RouteComponentProps) {
  const [contentElem, setContentElem] = useState<HTMLElement | null>(null);
  const [hoverActive, setHoverActive] = useState<string | null>(null);

  const { data } = useQuery<MarTecFeaturedQuery>(MAR_TEC_FEATURED);

  const strapiURL = useContext(StrapiURLContext);

  const isLg = useMinWidth(1024);
  const filteredArticles = [
    data?.servicesMarketingTechnology?.case_study_1,
    data?.servicesMarketingTechnology?.case_study_2,
  ];

  const title = `Marketing Technology Services${config.title_separator}${config.post_title}`;
  const metaDescription = "We are here to help you transform and grow your business through CRM, CMS and Marketing Automation.";
	const metaImage = config.meta_image;

  const contactUs = useRef<HTMLHeadingElement>(null)

  const executeScroll = () => {
    if (contactUs !== undefined || contactUs !== null) {
      contactUs.current?.scrollIntoView({ behavior: 'smooth' }) 
    }
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content={metaImage} />
      </Helmet>

      <StickyHeader />
      <ScrollBar boundary={contentElem} />
      <Menu />

      <main ref={setContentElem} className="main">
        <header className="w-full lg:py-64 sm:py-40 bg-onyx-dark relative mb-8">
          <img
            className="absolute inset-0 w-full h-full opacity-fade-b-50 object-cover"
            alt="Marketing Technology Services Header"
            src={require("../../assets/services/mar-tec/img/img-martech-subheader-bg.gif")}
          />
          <div className="absolute inset-0 bg-black opacity-60 opacity-fade-b-50" />
          <div className="content content-width-only absolute inset-0 m-auto flex flex-col items-center justify-center">
            <h1 className="h2 text-center text-white sm:mt-16">
              Marketing Technology Services
            </h1>
            <p className="font-bold text-center max-w-md lg:max-w-md">
              We are here to help you transform and grow your business through CRM, CMS and Marketing Automation.
            </p>
          </div>
        </header>

        <article className="content">
          <TextWithQuote
            className="mb-section"
            header="How Marketing Technology Can Work For You"
            contentNode={
              <>
                <p>How should I activate marketing automation for my business? Is my current CRM setup effective? We solved these challenges for many of our clients - and we can help you too.</p>
                <p>An integrated CRM, CMS, marketing automation and sales enablement platform will allow you to fully harness the power of marketing technology to grow your business.</p>
              </>
            }
          />

          <section className="w-full mb-section flex flex-col items-center justify-center">
            <div className="w-full flex sm:flex-col justify-center lg:mb-8 lg:space-x-8">
              <div className="sm:w-full lg:w-1/2 min-h-24 p-8 sm:mb-8 border-2 border-onyx-green flex">
                <div className="flex-1">
                  <h3 className="leading-none mb-2">Strategy</h3>
                  <p className="mb-0">
                    A well-planned MarTech strategy forms the cornerstone for creating great customer experience and business growth. Our team will listen to your needs, identify gaps and implement the right solutions for your long-term success.
                  </p>
                </div>
              </div>
              <div className="sm:w-full lg:w-1/2 min-h-24 p-8 sm:mb-8 border-2 border-onyx-green flex">
                <div className="flex-1">
                  <h3 className="leading-none mb-2">Expertise</h3>
                  <p className="mb-0">
                  We leverage on our hybrid skillset of experience design and technology expertise to help you implement a MarTech stack that is both functional and results-oriented.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex sm:flex-col justify-center lg:mb-8 lg:space-x-8">              
              <div className="sm:w-full lg:w-1/2 min-h-24 p-8 sm:mb-8 border-2 border-onyx-green flex">
                <div className="flex-1">
                  <h3 className="leading-none mb-2">
                    Integrate &amp; Configure
                  </h3>
                  <p className="mb-0">
                    MarTech needs specialist skills to set up correctly. The impact of poor setup can be a frustrating and expansive experience. We'll get the details right, so your MarTech is set up to support your business growth.
                  </p>
                </div>
              </div>
              <div className="sm:w-full lg:w-1/2 min-h-24 p-8 sm:mb-8 border-2 border-onyx-green flex">
                <div className="flex-1">
                  <h3 className="leading-none mb-2">Results</h3>
                  <p className="mb-0">
                    Most businesses that have implemented some form of marketing technology aren't reaping  the intended ROI. We'll help you get the most out of your technology investment.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="w-full mb-section flex flex-col items-center justify-center">
            <div onClick={executeScroll} className="marTechAnchor">GET FREE CONSULTATION</div>
          </section>
          <section className="w-full mb-section flex flex-col items-center justify-center">
            <h3 className="max-w-xl text-center mb-8 lg:mb-16">
              WE CAN PARTNER YOU IN TRANSFORMING YOUR BUSINESS{" "}
            </h3>
            <div className="flex flex-wrap lg:-mx-16">
              <div className="w-full lg:w-1/2 lg:px-16 mb-8">
                <img
                  alt="Strategy and Solutioning"
                  className="inline-block h-16 w-16 mb-4 object-center"
                  src={require("../../assets/services/mar-tec/svg/strategy-solutioning.svg")}
                />
                <p className="mb-2">
                  <b>Strategy and Solutioning</b>
                </p>
                <p>We are users first, technologist second. We will fully consider your objectives and current setup to derive a suitable strategy that will save you valuable time and money, and help you avoid costly mistakes.</p>
								<p>The HubSpot growth stack can support the marketing, sales and service needs of entire businesses but, in most cases, there are other tools and systems that need to be used in concert with it. </p>
              </div>

              <div className="w-full lg:w-1/2 lg:px-16 mb-8">
                <img
                  alt="CRM &amp; Marketing Automation"
                  className="inline-block h-16 w-16 mb-4 object-center"
                  src={require("../../assets/services/mar-tec/svg/crm-marketing-automation.svg")}
                />
                <p className="mb-2">
                  <b>CRM &amp; Marketing Automation </b>
                </p>
                <p>Your CRM and marketing automation platform are the foundations of your sales and marketing. If you're looking to move to a new CRM (such as HubSpot), or wanting to get more out of your existing system, we can help you plan, execute, and operationalise for success.</p>
              </div>

              <div className="w-full lg:w-1/2 lg:px-16 mb-8">
                <img
                  alt="CMS Web Development"
                  className="inline-block h-16 w-16 mb-4 object-center"
                  src={require("../../assets/services/mar-tec/svg/cms-web-development.svg")}
                />
                <p className="mb-2">
                  <b>CMS Web Development</b>
                </p>
                <p>Our team of in-house UI/UX designers, developers and project managers ensures that you get assured support and clear communication at every step of the project.</p>
								<p>We are experts in CMS platforms such as HubSpot and WordPress, and has implementation experience in other popular CMS platforms as well.</p>
              </div>

              <div className="w-full lg:w-1/2 lg:px-16 mb-8">
                <img
                  alt="Configuration &amp; integration"
                  className="inline-block h-16 w-16 mb-4 object-center"
                  src={require("../../assets/services/mar-tec/svg/configuration-integration.svg")}
                />
                <p className="mb-2">
                  <b>Configuration &amp; integration</b>
                </p>
                <p>Get our expert support to setup your CRM. As part of our onboarding services, we will work with you to configure deal stages, setup custom properties, connect sales teams inboxes, enable marketing automation and when required optionally, integrate it with third-party apps such as webinar tools, event management or e-Commerce solutions (such as Shopify or BigCommerce), so that you can harness the powerful features of HubSpot and reap value from it as quickly as possible.</p>
              </div>

              <div className="w-full lg:w-1/2 lg:px-16 mb-8">
                <img
                  alt="Chatbots &amp; Conversational Marketing"
                  className="inline-block h-16 w-16 mb-4 object-center"
                  src={require("../../assets/services/mar-tec/svg/chatbots-conversational-marketing.svg")}
                />
                <p className="mb-2">
                  <b>Chatbots &amp; Conversational Marketing</b>
                </p>
                <p>Conversational marketing is another effective tool for businesses to sell to customers. Chatbots provide an instant seamless customer sales experience and are an always-on. Talk to us about enabling this essential customer touchpoint that captures, qualifies, and connects you to your prospects in real-time.</p>
              </div>

              <div className="w-full lg:w-1/2 lg:px-16 mb-8">
                <img
                  alt="Tracking &amp; Analytics"
                  className="inline-block h-16 w-16 mb-4 object-center"
                  src={require("../../assets/services/mar-tec/svg/tracking-analytics.svg")}
                />
                <p className="mb-2">
                  <b>Tracking &amp; Analytics</b>
                </p>
                <p>Effective Marketing and Sales decisions are drawn from data-driven insights. Understanding true performance across a wide variety of tools, channels and platforms makes  tracking and analytics more challenging for businesses to manage. We can consult and recommend streamlined solutions to ensure crucial data is being tracked more effectively and that you have a meaningful view of it.</p>
              </div>

              <div className="w-full lg:w-1/2 lg:px-16 mb-8">
                <img
                  alt="Onboarding"
                  className="inline-block h-16 w-16 mb-4 object-center"
                  src={require("../../assets/services/mar-tec/svg/onboarding.svg")}
                />
                <p className="mb-2">
                  <b>Onboarding</b>
                </p>
                <p>Our HubSpot onboarding service prioritises the necessary setup to get you ready to kickstart your CRM and Marketing Automation activities, thus ensuring quicker adoption and faster ROI. Within our onboarding service we will assess your requirements and configure HubSpot accordingly, our expertise support will take your mind off the technicalities to focus on results.</p>
              </div>

              <div className="w-full lg:w-1/2 lg:px-16 mb-8">
                <img
                  alt="Training"
                  className="inline-block h-16 w-16 mb-4 object-center"
                  src={require("../../assets/services/mar-tec/svg/training.svg")}
                />
                <p className="mb-2">
                  <b>Training</b>
                </p>
                <p>Whether you require support in onboarding your local teams or customised training to complement existing knowledge base in HubSpot Academy, we can train you and your team through workshops and documentations to help you fully realise the benefits of the HubSpot platform and inbound marketing for successful growth.</p>
              </div>
            </div>
          </section>

          <section className="w-full mb-section flex flex-col items-center justify-center">
            <div onClick={executeScroll} className="marTechAnchor">GET FREE CONSULTATION</div>
          </section>

          <section className="w-full flex flex-wrap mb-section justify-center">
            <h3 className="max-w-xl text-center mb-8 lg:mb-16">OUR LATEST CASE STUDIES</h3>

            <div className="w-full flex flex-wrap lg:-mx-16">
              <div className="w-full lg:w-1/2 lg:px-2 my-2">
                <a href="/case-study/digital-income">
                  <article className="relative flex items-center mb-8">
                    <div className="self-start bg-onyx-black w-full sm:ratio-1/1 lg:ratio-4/3" style={{ transform: 'translate3d(0px, 0px, 0px' }}>
                      <img className="w-full h-full absolute inset-0 shadow-2xl transition-opacity duration-300 object-cover object-center opacity-25" alt="Digital Income" src={require('../../assets/digital-income.png')} />
                    </div>
                    <header className="absolute inset-y-0 lg:px-8 sm:px-4 flex flex-col justify-center right-0 left-0" style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
                      <div>
                        <div className="relative">
                          <h2 className="fill-effect hover:text-transparent lg:h2 lg:mb-3 sm:mb-3">Digital Income</h2>
                          <div className="absolute inset-0 transition-all duration-300 select-none clip-r">
                            <h2 className="text-onyx-light-gray lg:h2 lg:mb-3 sm:mb-3">Digital Income</h2>
                          </div>
                        </div>
                        <a href="#" className="tag mr-3 mb-3">Concept</a>
                        <a href="#" className="tag mr-3 mb-3">Social</a>
                        <a href="#" className="tag mr-3 mb-3">Animation</a>
                      </div>
                    </header>
                  </article>
                </a>
              </div>
              <div className="w-full lg:w-1/2 lg:px-2 my-2">
                <a href="/case-study/the-tatler-bar">
                  <article className="relative flex items-center mb-8">
                    <div className="self-start bg-onyx-black w-full sm:ratio-1/1 lg:ratio-4/3" style={{ transform: 'translate3d(0.00132824px, 0.000399676px, 0px)' }}>
                      <img className="w-full h-full absolute inset-0 shadow-2xl transition-opacity duration-300 object-cover object-center opacity-25" alt="The Tatler Bar" src={require('../../assets/the-tatler-bar.png')} />
                    </div>
                    <header className="absolute inset-y-0 lg:px-8 sm:px-4 flex flex-col justify-center right-0 left-0" style={{ transform: 'translate3d(0.000996177px, 0.000299757px, 0px)' }}>
                      <div>
                        <div className="relative">
                          <h2 className="fill-effect hover:text-transparent lg:h2 lg:mb-3 sm:mb-3">The Tatler Bar</h2>
                          <div className="absolute inset-0 transition-all duration-300 select-none clip-r">
                            <h2 className="text-onyx-light-gray lg:h2 lg:mb-3 sm:mb-3">The Tatler Bar</h2>
                          </div>
                        </div>
                        <a href="#" className="tag mr-3 mb-3">Ecommerce</a>
                      </div>
                    </header>
                </article>
                </a>
              </div>
            </div>
            
            {/* {filteredArticles.map((item) => {
              if (item == null) return null;
              
              return (
                <div
                  className="sm:w-full lg:w-1/2 lg:px-2 my-2"
                  key={item.id}
                  onMouseEnter={() => setHoverActive(item.id)}
                  onMouseLeave={() => setHoverActive(null)}
                >
                  <Anchor href={`/case-study/${item.slug}`}>
                    <ParallaxTile
                      overrideActive={
                        isLg ? hoverActive === item.id : undefined
                      }
                      className="mb-8"
                      imageClassName="w-full sm:ratio-1/1 lg:ratio-4/3"
                      textClassName="left-0"
                      imageUrl={`${strapiURL}${item.heroImage?.url ?? ""}`}
                      imageAlt={item.title}
                    >
                      {(active) => (
                        <div>
                          <TextHoverFill
                            as="h2"
                            headerClassName="lg:h2 lg:mb-3 sm:mb-3"
                            overrideHover={active}
                            text={item.title}
                          />
                          {item.tags?.map((tag) => (
                            <>
                              <a href="#" className="tag mr-3 mb-3">
                                {tag?.name.replace(" ", "\xa0")}
                              </a>{" "}
                            </>
                          ))}
                        </div>
                      )}
                    </ParallaxTile>
                  </Anchor>
                </div>
              );
            })} */}

            <div className="w-full flex flex-col items-center">
              <Link to="/case-studies">
                <div className="relative mx-auto">
                  <button className="unhover primary" value="submit">
                    View More
                  </button>
                  <div className="absolute inset-0 transition-all duration-300 clip-r">
                    <button className="hover primary" value="submit">
                      View More
                    </button>
                  </div>
                </div>
              </Link>
            </div>
          </section>
          <section className="w-full mb-section flex items-center justify-center lg:space-x-8 sm:flex-col">
            <img
              alt="HubSpot Solutions Partner Program - Diamond"
              className="w-full sm:max-w-40 lg:max-w-1/5 sm:mb-8"
              src={require("../../assets/services/mar-tec/img/diamond-badge-white.png")}
            />
            <div className="w-full lg:max-w-1/2">
              <h3 className="max-w-xl uppercase">Why work with us?</h3>
              <p>At Onyx Island, we harness the best technology in the market with our in-house expertise to help clients grow their business. Our experience in enabling CRM and Marketing Automation for businesses is recognised through us being certified as a <b>HubSpot Diamond Solutions Partner</b>. Being one of the top HubSpot Solutions Partner in the region gives us the opportunity to work with customers across different industries in the B2B and B2C sectors to succeed through dynamic inbound marketing campaigns, HubSpot CMS development, onboarding strategies, chatbots and more.</p>
              <p>We love using the HubSpot platform and are well-versed with its capabilities, and this in turn helps our customers get the most out of their investment. As a Diamond Solutions Partner we have first dips with the HubSpot team to make sure we're always able to offer the very latest tools and technology.</p>
            </div>
          </section>
          <section className="w-full mb-section flex flex-col items-center justify-center">
            <h3 className="max-w-xl text-center mb-0">
              We are well-versed in the following technologies too
            </h3>
            <p>
              Not using HubSpot? No worries, we are also experienced in these web technologies
            </p>
            <div className="flex flex-wrap justify-center px-4">
              <div className="block text-center lg:w-1/4 sm:w-1/2 sm:px-4 sm:py-6 lg:px-8 lg:py-4">
                <img
                  src={require("../../assets/services/mar-tec/img/salesforce.png")}
                  className="w-full h-full object-scale-down object-center"
                />
              </div>
              <div className="block text-center lg:w-1/4 sm:w-1/2 sm:px-4 sm:py-6 lg:px-8 lg:py-4">
                <img
                  src={require("../../assets/services/mar-tec/img/aem.png")}
                  className="w-full h-full object-scale-down object-center"
                />
              </div>
              <div className="block text-center lg:w-1/4 sm:w-1/2 sm:px-4 sm:py-6 lg:px-8 lg:py-4">
                <img
                  src={require("../../assets/services/mar-tec/img/mailchimp.png")}
                  className="w-full h-full object-scale-down object-center"
                />
              </div>
              <div className="block text-center lg:w-1/4 sm:w-1/2 sm:px-4 sm:py-6 lg:px-8 lg:py-4">
                <img
                  src={require("../../assets/services/mar-tec/img/react.png")}
                  className="w-full h-full object-scale-down object-center"
                />
              </div>
              <div className="block text-center lg:w-1/4 sm:w-1/2 sm:px-4 sm:py-6 lg:px-8 lg:py-4">
                <img
                  src={require("../../assets/services/mar-tec/img/wordpress.png")}
                  className="w-full h-full object-scale-down object-center"
                />
              </div>
              <div className="block text-center lg:w-1/4 sm:w-1/2 sm:px-4 sm:py-6 lg:px-8 lg:py-4">
                <img
                  src={require("../../assets/services/mar-tec/img/laravel.png")}
                  className="w-full h-full object-scale-down object-center"
                />
              </div>
              <div className="block text-center lg:w-1/4 sm:w-1/2 sm:px-4 sm:py-6 lg:px-8 lg:py-4">
                <img
                  src={require("../../assets/services/mar-tec/img/shopify.png")}
                  className="w-full h-full object-scale-down object-center"
                />
              </div>
              <div className="block text-center lg:w-1/4 sm:w-1/2 sm:px-4 sm:py-6 lg:px-8 lg:py-4">
                <img
                  src={require("../../assets/services/mar-tec/img/bigcommerce.png")}
                  className="w-full h-full object-scale-down object-center"
                />
              </div>
            </div>
          </section>
          <section
            id="contact-us"
            className="w-full mb-section flex flex-col items-center"
          >
            <h3 className="text-center" ref={contactUs} >
              Let's talk about your Marketing Technology plans now
            </h3>
            <Services_MarTechForm className="max-w-lg mb-section" />
          </section>
        </article>
      </main>
      <GoToTopButton boundary={contentElem} />
      <Footer />
    </>
  );
}
