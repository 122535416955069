import * as React from 'react';
import { animated, useSpring, useTransition } from 'react-spring';
import cx from '../utilities/cx';

interface DropdownProps {
	className?: string;
	placeholder?: string;
	options: { [value: string]: string; };
	optionsOrder?: string[];
	value: string;
	onChange(value: string): void;
}

const paths = [
	"M0.5,0.5 L4.75,4.25, L8.7,0.5",
	"M0.8,4.25 L4.75,0.5, L8.7,4.25",
];

const Dropdown = React.forwardRef<HTMLSelectElement, DropdownProps>(({ className, placeholder = 'Please Select', options, optionsOrder, value, onChange }, ref) => {
	const [open, setOpen] = React.useState(false);
	const openAnim = useTransition(open, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: { tension: 360, friction: 34 },
	});
	const { d } = useSpring({ d: paths[open ? 1 : 0], config: { tension: 360, friction: 34 } });

	React.useEffect(() => {
		const handler = () => setOpen(false);

		window.addEventListener('click', handler);

		return () => window.removeEventListener('click', handler);
	}, []);

	return (
		<>
			<select ref={ref} className='hidden' value={value} onChange={e => onChange(e.target.value)}>
				{(optionsOrder?.map(v => [v, options[v]]) ?? Object.entries(options)).map(([v, k]) => (
					<option key={v} value={v}>{k}</option>
				))}
			</select>

			<div className={cx('select relative', open && 'active', className)} onClick={e => { e.stopPropagation(); setOpen(b => !b); }}>
				{options[value] || <span className='select-placeholder'>{placeholder}</span>}
				<svg className='absolute inset-y-0 right-0 my-auto mr-3 text-onyx-green' width='10' height='5' viewBox="0 0 9.5 5">
					<animated.path fill="none" stroke="currentColor" d={d} />
				</svg>
				{openAnim.map(({ item, key, props }) => item && (
					<animated.ul key={key} className='absolute top-full inset-x-0 bg-onyx-black z-10' style={{ ...props, marginTop: 1 }}>
						{(optionsOrder?.map(v => [v, options[v]]) ?? Object.entries(options)).map(([v, k]) => (
							<li className='hover:bg-onyx-dark-gray p-3 transition duration-300' onClick={e => { e.stopPropagation(); onChange(v); setOpen(false); }}>{k}</li>
						))}
					</animated.ul>
				))}
			</div>
		</>
	);
});

export default Dropdown;