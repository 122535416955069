import * as React from "react";
import cx from "../utilities/cx";

type PacakgeFeatureProps = {
	notIncluded?: boolean;
	children?: React.ReactNode;
};

export default function PackageFeature({ notIncluded, children }: PacakgeFeatureProps) {
	return (
		<>
			<li className="flex items-start mb-4">
				<img className={cx(notIncluded && "invisible", "w-3.5 h-3.5 box-content py-0.75 mr-4")} src={require("../../assets/services/soc-med/svg/icon-tick.svg")} />
				<span className={cx(notIncluded && "text-onyx-mid-gray line-through", "text-sm")}>
					{children}
				</span>
			</li>
		</>
	);
}0