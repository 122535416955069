import { useContext } from "react";
import StrapiURLContext from "../utilities/strapiURL";

type VisualProps = {
	mime: string;
	src: string;
	autoPlay?: boolean;
};

export default function Visual({ mime, src, autoPlay }: VisualProps) {
	if (/^image\//.test(mime)) {
		return <img src={src} />;
	}

	if (/^video\//.test(mime)) {
		return <video autoPlay={autoPlay} muted={autoPlay} src={src} />;
	}

	return null;
}
