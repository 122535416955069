import * as React from 'react';
import lottie, { AnimationItem } from 'lottie-web';
const animationData = require('../../assets/tagline.json');

const AnimatedLogoText: React.FC<{ className?: string; }> = ({ className }) => {
	const { ref } = useAnimation();

	return (
		<div className={className} ref={ref}></div>
	);
};

function useAnimation() {
	const ref = React.useRef<HTMLDivElement>(null);
	const [anim, setAnim] = React.useState<AnimationItem | undefined>();

	React.useEffect(() => {
		if (ref.current != null) {
			const lottieIns = lottie.loadAnimation({
				container: ref.current,
				animationData,
				renderer: 'svg',
				loop: true,
				autoplay: true,
			});

			// setTimeout(() => {
			// 	lottieIns.playSegments([[0, 180]], true);
			// 	// lottieIns.playSegments([[0, 217], [0, 60]], true);
			// }, 1000);

			setAnim(lottieIns);
		}
	}, []);

	return { ref };
}

export default AnimatedLogoText;