import "../styles/montauk.scss";
import cx from "../utilities/cx";

type AnimatedMontaukProps = {
  className?: string;
};

export default function AnimatedMontauk({ className }: AnimatedMontaukProps) {
  return (
    <div className={cx("montauk-container", className)}>
      <div className="montauk-wrap">
        {Array(100)
          .fill(null)
          .map((_, i) => (
            <div key={i} className="montauk-triangle" />
          ))}
      </div>
    </div>
  );
}
