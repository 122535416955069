import * as React from 'react';
import lottie, { AnimationItem } from 'lottie-web';
const animationData = require('../../assets/menu.json');

const AnimatedMenuIcon: React.FC<{ className?: string; open: boolean; }> = ({ className, open }) => {
	const { ref, toCross, toLines } = useAnimation();

	React.useEffect(() => {
		if (open) {
			toCross();
		} else {
			toLines();
		}
	}, [open]);

	return (
		<div className={className} ref={ref}></div>
	);
};

function useAnimation() {
	const ref = React.useRef<HTMLDivElement>(null);
	const [anim, setAnim] = React.useState<AnimationItem | undefined>();
	const [open, setOpen] = React.useState(false);

	React.useEffect(() => {
		if (ref.current != null) {
			const lottieIns = lottie.loadAnimation({
				container: ref.current,
				animationData,
				renderer: 'svg',
				loop: false,
				autoplay: false,
			});

			setAnim(lottieIns);
		}
	}, []);

	function toCross() {
		anim?.playSegments([0, 14], true);
	}

	function toLines() {
		anim?.playSegments([15, 30], true);
	}

	return { ref, toCross, toLines };
}

export default AnimatedMenuIcon;