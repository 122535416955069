import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { AllTagsQuery } from '../../__generated__/AllTagsQuery';
import nonNull from '../utilities/nonNull';
import FilterTags from './FilterTags';
import cx from '../utilities/cx';
import Dropdown from './Dropdown';

interface FilterListProps {
	className?: string;
	onClick: (tagId: string | null) => void;
}

const ALL_TAGS = {
  tags: [{
      id: "1",
      name: "Design",
      __typename: "Tag"
    },
    {
      id: "2",
      name: "Technology",
      __typename: "Tag"
    },
    {
      id: "3",
      name: "Concept",
      __typename: "Tag"
    },
    {
      id: "4",
      name: "Social",
      __typename: "Tag"
    },
    {
      id: "5",
      name: "Solution",
      __typename: "Tag"
    },
    {
      id: "6",
      name: "User experience",
      __typename: "Tag"
    },
    {
      id: "7",
      name: "UI layouts",
      __typename: "Tag"
    },
    {
      id: "8",
      name: "Animation",
      __typename: "Tag"
    },
    {
      id: "9",
      name: "Campaign",
      __typename: "Tag"
    },
    {
      id: "10",
      name: "Ecommerce",
      __typename: "Tag"
    },
    {
      id: "11",
      name: "Works",
      __typename: "Tag"
    }
  ]
};

const FilterList: React.FC<FilterListProps> = ({ className, onClick }) => {
	const data  = ALL_TAGS;
	const [active, setActive] = React.useState<string | null>(null);

  React.useEffect(() => {
    console.log('FilterList data = ',data)
  }, [data])

	const clickHandler = (tagId: string | null) => {
		onClick(tagId || null);
		setActive(tagId || null);
	};

	React.useEffect(() => {
    console.log('FilterList active = ',active)
  }, [active])

	const options = data?.tags?.filter(nonNull)?.reduce((memo, tag) => {
		return { ...memo, [tag.id]: tag.name };
	}, {}) ?? {};

	return (
		<div className={cx('w-full flex justify-center mb-16', className)}>
			<Dropdown className='w-64' placeholder='All Tags' value={active ?? ''} options={{ '': 'All Tags', ...options }} optionsOrder={['', ...Object.keys(options)]} onChange={clickHandler} />
			{/* <ul className='sm:hidden list-none'>
				<FilterTags active={active === null} tagName='All Tags' onClick={() => clickHandler(null)} />
				{data?.tags?.filter(nonNull)?.map(tag => (
					<FilterTags key={tag.id} active={active === tag.id} tagName={tag.name} onClick={() => clickHandler(tag?.id)} />
				))}
			</ul> */}
		</div>
	);
};

export default FilterList;