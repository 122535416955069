import { useQuery } from "@apollo/react-hooks";
import { RouteComponentProps } from "@reach/router";
import { gql } from "apollo-boost";
import * as React from "react";
import { useContext, useState } from "react";
import Helmet from "react-helmet";
import Slider from "react-slick";
import { SocMedFeaturedQuery } from "../../__generated__/SocMedFeaturedQuery";
import Anchor from "../components/Anchor";
import Footer from "../components/Footer";
import GoToTopButton from "../components/GoToTopButton";
import { Menu } from "../components/Menu";
import PackageFeature from "../components/PackageFeature";
import PackageHeader from "../components/PackageHeader";
import ParallaxTile from "../components/ParallaxTile";
import ScrollBar from "../components/ScrollBar";
import Services_SocMedForm from "../components/Services_SocMedForm";
import StickyHeader from "../components/StickyHeader";
import TextHoverFill from "../components/TextHoverFill";
import TextWithQuote from "../components/TextWithQuote";
import config from "../utilities/config";
import cx from "../utilities/cx";
import StrapiURLContext from "../utilities/strapiURL";
import useMinWidth from "../utilities/useMinWidth";

const SOC_MED_FEATURED = gql`
	query SocMedFeaturedQuery {
		servicesSocialMedia {
			case_study_1 {
				id
				title
				slug
				heroImage {
					url
				}
				tags {
					id
					name
				}
			}
			case_study_2 {
				id
				title
				slug
				heroImage {
					url
				}
				tags {
					id
					name
				}
			}
		}
	}
`;

export default function Services_SocMed({ }: RouteComponentProps) {
	const [contentElem, setContentElem] = useState<HTMLElement | null>(null);
	const [hoverActive, setHoverActive] = useState<string | null>(null);

	const { data } = useQuery<SocMedFeaturedQuery>(SOC_MED_FEATURED);

	const strapiURL = useContext(StrapiURLContext);

	const isLg = useMinWidth(1024);
	const filteredArticles = [
		data?.servicesSocialMedia?.case_study_1,
		data?.servicesSocialMedia?.case_study_2,
	];

	const title = config.post_title;
	const metaDescription = "Onyx Island provides expertise support in social media management, social content creation, social strategy and social media marketing campaigns across Facebook, Instagram, YouTube, Twitter, TikTok, LinkedIN.";
	const metaImage = config.meta_image;

	return (
		<>
			<Helmet>
				<title>Social Media Marketing Singapore{config.title_separator}{title}</title>
				<meta name="description" content={metaDescription} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={metaDescription} />
				<meta property="og:image" content={metaImage} />
				<meta property="twitter:title" content={title} />
				<meta property="twitter:description" content={metaDescription} />
				<meta property="twitter:image" content={metaImage} />
			</Helmet>

			<StickyHeader />
			<ScrollBar boundary={contentElem} />
			<Menu />

			<main ref={setContentElem} className="main">
				<header className="w-full lg:py-64 sm:py-40 bg-onyx-dark relative mb-8">
					<img className="absolute inset-0 w-full h-full opacity-fade-b-50 object-cover" alt="Social Media Marketing Header" src={require("../../assets/services/soc-med/img-sm-marketing-subheader-bg.gif")} />
					<div className="absolute inset-0 bg-black opacity-60 opacity-fade-b-50" />
					<div className="content content-width-only absolute inset-0 m-auto flex flex-col items-center justify-center">
						<h1 className="h2 text-center text-white sm:mt-16">Social Media Marketing</h1>
						<p className="font-bold text-center">4.6 out of 5.1 million internet users in Singapore use social media.<br />Does your business? </p>
					</div>
				</header>

				{(
					<div className="absolute sm:top-4 sm:flex sm:justify-center sm:right-auto top-6 right-14">
						<img src={require("../../assets/HAA-rect-banner.png")} alt="Hashtag Award Winners" className='haa-badge smaller' />
					</div>
				)}

				<article className="content">
					<TextWithQuote className="mb-section"
						header="Why social media is an integral part of any business marketing strategy"
						content="Brands use social media platforms to connect with consumers, increase brand awareness, drive traffic, and boost revenue and sales. All through social media apps and sites like Facebook, Twitter, LinkedIn, Instagram, TikTok, YouTube, and Reddit."
					/>

					<section className='w-full lg:w-2/3 flex flex-wrap mb-section -mx-4'>
						<div className="w-full lg:w-1/2 text-center px-4 mb-8">
							<img alt="Engage" className="inline-block w-16 mb-4" src={require("../../assets/services/soc-med/svg/icon-engage.svg")} />
							<p className="text-center mb-2"><b>Engage</b></p>
							<p className="text-center">
								Give your audience the content they crave, resonate with their needs and create positive experiences.
							</p>
						</div>

						<div className="w-full lg:w-1/2 text-center px-4 mb-8">
							<img alt="Monitor" className="inline-block w-16 mb-4" src={require("../../assets/services/soc-med/svg/icon-monitor.svg")} />
							<p className="text-center mb-2"><b>Monitor</b></p>
							<p className="text-center">
								Listen and understand online conversations about your brand, products, services, trends, and competitors.
							</p>
						</div>

						<div className="w-full lg:w-1/2 text-center px-4 mb-8">
							<img alt="Analyse" className="inline-block w-16 mb-4" src={require("../../assets/services/soc-med/svg/icon-analyse.svg")} />
							<p className="text-center mb-2"><b>Analyse</b></p>
							<p className="text-center">
								Use data to gain insights, identify trends, and conduct audience and market research.
							</p>
						</div>

						<div className="w-full lg:w-1/2 text-center px-4 mb-8">
							<img alt="Optimise" className="inline-block w-16 mb-4" src={require("../../assets/services/soc-med/svg/icon-measure.svg")} />
							<p className="text-center mb-2"><b>Optimise</b></p>
							<p className="text-center">
								Amplify the tactics that work for your business, discard the strategies that don't.
							</p>
						</div>
					</section>

					<section className='w-full mb-section flex flex-col items-center justify-center'>
						<h3 className="max-w-xl text-center mb-2">By 2025, there will be 5 million social&nbsp;network users in Singapore</h3>
						<p className="text-center">*Source: Statista Research Department</p>

						<div className='w-full flex sm:flex-col justify-center lg:mb-8'>
							<div className='sm:w-full lg:w-1/3 min-h-24 p-8 sm:mb-8 lg:mr-4 border-2 border-onyx-green flex'>
								<img alt="Male" className='w-8 h-8 mr-8' src={require("../../assets/services/soc-med/svg/icon-male.svg")} />
								<div className='flex-1'>
									<h3 className="leading-none mb-2">18%</h3>
									<p className="mb-0">of the local social media advertising audience is between 25-34 years old and are male</p>
								</div>
							</div>
							<div className='sm:w-full lg:w-1/3 min-h-24 p-8 sm:mb-8 lg:mr-4 border-2 border-onyx-green flex'>
								<img alt="Facebook" className='w-8 h-8 mr-8' src={require("../../assets/services/soc-med/svg/icon-facebook.svg")} />
								<div className='flex-1'>
									<h3 className="leading-none mb-2">3.7</h3>
									<p className="mb-0">million of Singapore's addressable advertising audience lives on Facebook</p>
								</div>
							</div>
							<div className='sm:w-full lg:w-1/3 min-h-24 p-8 sm:mb-8 lg:mr-4 border-2 border-onyx-green flex'>
								<img alt="Challenges" className='w-8 h-8 mr-8' src={require("../../assets/services/soc-med/svg/icon-challenges.svg")} />
								<div className='flex-1'>
									<h3 className="leading-none mb-2">74%</h3>
									<p className="mb-0">social media penetration with Facebook losing younger audience to Instagram &amp; TikTok</p>
								</div>
							</div>
						</div>
					</section>

					<section className='w-full mb-section flex flex-col items-center justify-center'>
						<h3 className="max-w-xl text-center mb-8 lg:mb-16">How we can help your business dominate social media marketing </h3>

						<div className="flex flex-wrap lg:-mx-16">
							<div className="w-full lg:w-1/2 lg:px-16 mb-8">
								<img alt="Strategise" className="inline-block w-16 mb-4" src={require("../../assets/services/soc-med/svg/icon-social-media-strategy.svg")} />
								<p className="mb-2"><b>Strategise</b></p>
								<p>We combine creative, marketing, communication, and technology solutions to produce unified social media strategies that achieve your business goals.</p>
							</div>

							<div className="w-full lg:w-1/2 lg:px-16 mb-8">
								<img alt="Create &amp; Curate Content" className="inline-block w-16 mb-4" src={require("../../assets/services/soc-med/svg/icon-content-creation.svg")} />
								<p className="mb-2"><b>Create &amp; Curate Content </b></p>
								<p>Engage your community with continuous fresh content in your brand voice.</p>
							</div>

							<div className="w-full lg:w-1/2 lg:px-16 mb-8">
								<img alt="Plan &amp; Publish" className="inline-block w-16 mb-4" src={require("../../assets/services/soc-med/svg/icon-social-campaign.svg")} />
								<p className="mb-2"><b>Plan &amp; Publish</b></p>
								<p>Our dedicated social media team plans your content in advance at the right time and frequency. This ensures your business reaches a large target audience with the content they want to see.</p>
							</div>

							<div className="w-full lg:w-1/2 lg:px-16 mb-8">
								<img alt="Manage Your Community" className="inline-block w-16 mb-4" src={require("../../assets/services/soc-med/svg/icon-community-management.svg")} />
								<p className="mb-2"><b>Manage Your Community</b></p>
								<p>We listen and engage with your audience when they comment on your posts, tag you on social media platforms, message you directly, or discuss your brand, products, and services on the internet.</p>
							</div>

							<div className="w-full lg:w-1/2 lg:px-16 mb-8">
								<img alt="Analyse Your Efforts" className="inline-block w-16 mb-4" src={require("../../assets/services/soc-med/svg/icon-analytics.svg")} />
								<p className="mb-2"><b>Analyse Your Efforts</b></p>
								<p>Receive comprehensive reports from our team each month that track how your social media marketing effort is performing.</p>
							</div>

							<div className="w-full lg:w-1/2 lg:px-16 mb-8">
								<img alt="Advertise" className="inline-block w-16 mb-4" src={require("../../assets/services/soc-med/svg/icon-paid-social-ad.svg")} />
								<p className="mb-2"><b>Advertise</b></p>
								<p>Reach your desired target audience on a wider scale with content and campaigns based on best practices gleaned from years of our experience.</p>
							</div>
						</div>
					</section>

					<section className='w-full mb-section flex flex-col items-center justify-center'>
						<h3 className="max-w-xl text-center mb-8 lg:mb-16">Our packages</h3>
						<div className="w-full">
							<PackageSlider />
						</div>
					</section>

					<TextWithQuote className="mb-8"
						header="Why clients keep coming back to us for social media marketing "
						contentNode={
							<>
								<p>Our interdisciplinary approach helps you foster lasting relationships with your consumers. Our social media team comprises creative-trained producers who work alongside the creative team's copywriters and art directors. This helps us go beyond creating content to tailor-made, data-led strategies that convert.</p>
								<p>The social media team plans, manages and maximises your social media presence whether a paid, organic, or blended approach is required. Our experts work with influencers and in-house teams to create social media material that facilitates the sharing of ideas and opinions - keeping your audience coming back for more.</p>
								<p>But don't just take our word for it. Have a look at our successful social media marketing campaigns.</p>
							</>
						}
					/>

					<img src={require('../../assets/HAA-banner.png')} alt="" className="hidden lg:block mt-10 mb-28" />
					<img src={require('../../assets/HAA-mobile.png')} alt="" className="block lg:hidden mt-2 mb-16" />


					<section className="w-full flex flex-wrap mb-section">
						<div className="sm:w-full lg:w-1/2 lg:px-2 my-2">
							<a href="/case-study/social-media-packages">
								<article className="relative flex items-center mb-8">
									<div className="self-start bg-onyx-black w-full sm:ratio-1/1 lg:ratio-4/3" style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
										<img className="w-full h-full absolute inset-0 shadow-2xl transition-opacity duration-300 object-cover object-center opacity-25" alt="Social Media Packages" src={require('../../assets/casestudies_socialpost_masthead.png')} />
										<img alt="Hashtag Award Winners" className="absolute right-3 bottom-3 haa-badge opacity-100" src={require('../../assets/HAA-rect-banner.png')} />
									</div>
									<header className="absolute inset-y-0 lg:px-8 sm:px-4 flex flex-col justify-center right-0 left-0" style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
										<div>
											<div className="relative">
												<h2 className="fill-effect hover:text-transparent lg:h2 lg:mb-3 sm:mb-3">Social Media Packages</h2>
												<div className="absolute inset-0 transition-all duration-300 select-none clip-r">
													<h2 className="text-onyx-light-gray lg:h2 lg:mb-3 sm:mb-3">Social Media Packages</h2>
												</div>
											</div>
											<a href="#" className="tag mr-3 mb-3">Social</a>
										</div>
									</header>
								</article>
							</a>
						</div>
						
						<div className="sm:w-full lg:w-1/2 lg:px-2 my-2">
							<a href="/case-study/digital-income">
								<article className="relative flex items-center mb-8">
									<div className="self-start bg-onyx-black w-full sm:ratio-1/1 lg:ratio-4/3" style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
										<img className="w-full h-full absolute inset-0 shadow-2xl transition-opacity duration-300 object-cover object-center opacity-25" alt="Digital Income" src={require('../../assets/digital-income.png')} />
									</div>
									<header className="absolute inset-y-0 lg:px-8 sm:px-4 flex flex-col justify-center right-0 left-0" style={{ transform: 'translate3d(0px, 0px, 0px' }}>
										<div>
											<div className="relative">
												<h2 className="fill-effect hover:text-transparent lg:h2 lg:mb-3 sm:mb-3">Digital Income</h2>
												<div className="absolute inset-0 transition-all duration-300 select-none clip-r">
													<h2 className="text-onyx-light-gray lg:h2 lg:mb-3 sm:mb-3">Digital Income</h2>
												</div>
											</div>
											<a href="#" className="tag mr-3 mb-3">Concept</a>
											<a href="#" className="tag mr-3 mb-3">Social</a>
											<a href="#" className="tag mr-3 mb-3">Animation</a>
										</div>
									</header>
								</article>
							</a>
						</div>
					</section>

					
					{/* <section className="w-full flex flex-wrap mb-section">
						{filteredArticles.map((item) => {
							if (item == null) return null;
							return (
								<div className="sm:w-full lg:w-1/2 lg:px-2 my-2" key={item.id} onMouseEnter={() => setHoverActive(item.id)} onMouseLeave={() => setHoverActive(null)}>
									<Anchor href={`/case-study/${item.slug}`}>
										<ParallaxTile
											overrideActive={isLg ? hoverActive === item.id : undefined}
											className='mb-8'
											imageClassName='w-full sm:ratio-1/1 lg:ratio-4/3'
											textClassName='left-0'
											imageUrl={`${strapiURL}${item.heroImage?.url ?? ''}`}
											imageAlt={item.title}
											tags={item?.tags}
											title={item?.title}
										>
											{active => (
												<div>
													<TextHoverFill as="h2" headerClassName='lg:h2 lg:mb-3 sm:mb-3' overrideHover={active} text={item.title} />
													{item.tags?.map(tag => (
														<>
															<a href='#' className='tag mr-3 mb-3'>{tag?.name.replace(" ", "\xa0")}</a>{" "}
														</>
													))}
												</div>
											)}
										</ParallaxTile>
									</Anchor>
								</div>
							);
						})}
					</section> */}

					<section id="contact-us" className='w-full mb-section flex flex-col items-center'>
						<h3 className="text-center">Contact Us</h3>
						<p className="text-center mb-8">Fill out the form below with your enquiries and our social media team will get back to you.</p>

						<Services_SocMedForm className='max-w-lg mb-section' />
					</section>
				</article>

			</main>
			<GoToTopButton boundary={contentElem} />
			<Footer />
		</>
	);
};

function PackageSlider() {
	const [current, setCurrent] = useState(1);
	const isLg = useMinWidth(1024);
	return (
		<Slider className="flex flex-col-reverse slick-align-top"
			afterChange={i => setCurrent(i)}
			slidesToShow={isLg ? 3 : 1}
			initialSlide={1}
			dots
			customPaging={i => (
				<div className={cx( i === current && "text-onyx-green", "px-2")}>
					{"ABC"[i]}
				</div>
			)}
			prevArrow={<></>} nextArrow={<></>}
		>

			<div className="w-1/3 outline-none px-1 sm:pt-8 lg:px-4">
				<PackageHeader
					packageName="Package A"
					price="3,500"
				/>

				<ul className="mt-8 px-2">
					<PackageFeature>4 graphic collaterals per month<br />(Collaterals consists of 4 static product shots)</PackageFeature>
					<PackageFeature>Monthly content planning and copywriting</PackageFeature>
					<PackageFeature>Studio photoshoot of products</PackageFeature>
					<PackageFeature>Ads Management</PackageFeature>
					<PackageFeature>Monthly digital ads reporting</PackageFeature>
					<PackageFeature>Community Management (Spam/Comments monitoring)</PackageFeature>
					<PackageFeature>3 Months Commitment</PackageFeature>
					<PackageFeature>Monthly Payment</PackageFeature>
				</ul>
			</div>

			<div className="w-1/3 outline-none px-1 sm:pt-8 lg:px-4">
				<PackageHeader
					recom
					packageName="Package B"
					price="5,500"
				/>

				<ul className="mt-8 px-2">
					<PackageFeature>8 graphic collaterals per month<br />(Collaterals consists of 6 static product shots and 2 styled photography or videos)</PackageFeature>
					<PackageFeature>Monthly content planning and copywriting</PackageFeature>
					<PackageFeature>Studio photoshoot of products</PackageFeature>
					<PackageFeature>Ads Management &amp;&nbsp;Optimisation</PackageFeature>
					<PackageFeature>Monthly digital ads reporting</PackageFeature>
					<PackageFeature>Community Management (Spam/Comments monitoring)</PackageFeature>
					<PackageFeature>6 Months Commitment</PackageFeature>
					<PackageFeature>Monthly Payment</PackageFeature>
					<PackageFeature>Facebook &amp; IG Strategies (one time strategy)</PackageFeature>
				</ul>
			</div>

			<div className="w-1/3 outline-none px-1 sm:pt-8 lg:px-4">
				<PackageHeader
					packageName="Package C"
					price="8,000"
				/>

				<ul className="mt-8 px-2">
					<PackageFeature>12 graphic collaterals per month<br />(Collaterals consists of 9 static product shots and 3 styled photography or videos)</PackageFeature>
					<PackageFeature>Monthly content planning and copywriting</PackageFeature>
					<PackageFeature>Studio photoshoot of products</PackageFeature>
					<PackageFeature>Ads Management &amp;&nbsp;Optimisation</PackageFeature>
					<PackageFeature>Monthly digital ads reporting</PackageFeature>
					<PackageFeature>Community Management (Spam/Comments monitoring)</PackageFeature>
					<PackageFeature>6 Months Commitment</PackageFeature>
					<PackageFeature>Monthly Payment</PackageFeature>
					<PackageFeature>Facebook &amp; IG Strategies (one time strategy)</PackageFeature>
				</ul>
			</div>

		</Slider>
	);
}