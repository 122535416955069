import { gql } from "apollo-boost";

export const GET_CASE_STUDY = gql`
	query GetCaseStudy($slug: String!) {
		articleBySlug(slug: $slug) {
			id
			title
			meta_description
			meta_image {
				url
			}
			year
			tags(sort: "name:asc") {
				id
				name
			}
			project_url
			heroImage {
				url
			}
			opening_paragraph
			contact_us_paragraph
			body {
				__typename
				... on ComponentArticleComponentsTextWithLeftQuote {
					id
					swap_position
					left_quote
					right_body
				}
				... on ComponentArticleComponentsText {
					id
					content
				}
				... on ComponentArticleComponents2ColumnImage {
					id
					images {
						id
						url
					}
				}
				... on ComponentArticleComponentsVideoEmbed {
					id
					youtube_url
				}
				... on ComponentArticleComponentsTextWithImage {
					id
					image {
						url
					}
					alt
					text
					image_right
				}
				... on ComponentArticleComponents3ColumnImage {
					id
					images {
						id
						mime
						url
					}
				}
				... on ComponentArticleComponentsDivider {
					id
					na
				}
				... on ComponentArticleComponentsQuote {
					id
					title
					body {
						main_quote
						quotee
					}
				}
				... on ComponentArticleComponentsImageCarousel {
					id
					images {
						id
						url
					}
				}
				... on ComponentArticleComponentsTextStats {
					id
					body {
						id
						header
						box_1
						box_2
						box_3
						box_4
					}
				}
				... on ComponentArticleComponentsImageStats {
					id
					body {
						id
						image_1 {
							url
						}
						box_1
						image_2 {
							url
						}
						box_2
						image_3 {
							url
						}
						box_3
					}
				}
				... on ComponentArticleComponentsSingleImage {
					id
					alt
					image {
						url
					}
				}
				... on ComponentArticleComponentsLottieAnimation {
					id
					animation_data
				}
				... on ComponentArticleComponentsTextWithBackground {
					id
					background {
						url
					}
					content
				}
				... on ComponentArticleComponentsDoubleTitle {
					id
					title
					subtitle
				}
			}
		}
	}
`;

export const NEXT_CASE_STUDY = gql`
	query NextCaseStudy($id: ID!) {
		nextArticle(id: $id) {
			id
			title
			slug
			heroImage {
				url
			}
		}
	}
`;
