import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import Anchor from './Anchor';
import TextHoverFill from './TextHoverFill';
import AnimatedMenuIcon from './AnimatedMenuIcon';
import useMeasure from '../utilities/useMeasure';

export const Menu = () => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openServices, setOpenServices] = React.useState(false);
  const [bindServices, servicesRect] = useMeasure<HTMLDivElement>();
  const servicesProps = useSpring({
    height: openServices ? servicesRect.height : 0,
    marginTop: openServices ? '1.5rem' : '0rem',
    marginBottom: openServices ? "2rem" : "0rem",
    opacity: openServices ? 1 : 0,
  });

  const menuHandler = () => {
    if (openMenu) {
      document.body.classList.remove('overflow-hidden');
      setOpenMenu(false);
    } else {
      document.body.classList.add('overflow-hidden');
      setOpenMenu(true);
    }
  };

  React.useEffect(() => {
    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  const menuTransition = useSpring({
    left: openMenu ? '0%' : '100%',
    config: { mass: 5, tension: 500, friction: 100 },
  });

  const closeMenu = () => {
    document.body.classList.remove('overflow-hidden');
    setOpenMenu(false);
  };

  return (
    <>
      <animated.div className='bg-onyx-dark w-full fixed left-0 inset-y-0 z-50' style={menuTransition}>
        {/* <div role='button' onClick={menuHandler} className='absolute inset-y-0 right-0 lg:mr-8 sm:mr-2 py-2 h2 flex lg:items-center sm:items-start transition-opacity duration-300 hover:opacity-50'>
          <img src={require('../../assets/icon-menu-after.svg')} />
        </div> */}
        <nav className='absolute inset-y-0 lg:right-0 sm:left-0 ml-12 mr-24 flex flex-col justify-center lg:text-right'>
          <ul>
            {/* <li className='lg:my-2 sm:my-8 h2 fill-effect'>
              <Anchor className='no-underline' href='/' onClick={closeMenu}>
                <TextHoverFill headerClassName='h2' text='Home' />
              </Anchor>
            </li> */}

            <li className='lg:my-2 sm:my-8 h2 fill-effect'>
              <div className="cursor-pointer" onClick={() => setOpenServices(b => !b)}>
                <TextHoverFill headerClassName='h2 sm:mb-0' text='Services' />
              </div>
              <animated.div className="border-r border-onyx-green pr-8 overflow-hidden" style={servicesProps}>
                <div ref={bindServices.ref}>
                  <Anchor className='no-underline' href='/services/social-media-marketing' onClick={closeMenu}>
                    <TextHoverFill headerClassName='h3 mb-4' text='Social Media Marketing' />
                  </Anchor>
                  <Anchor className='no-underline' href='/services/marketing-technology' onClick={closeMenu}>
                    <TextHoverFill headerClassName='h3 mb-4' text='Marketing Technology' />
                  </Anchor>
                </div>
              </animated.div>
            </li>

            <li className='lg:my-2 sm:my-8 h2 fill-effect'>
              <Anchor className='no-underline' href='/case-studies' onClick={closeMenu}>
                <TextHoverFill headerClassName='h2' text='Case Studies' />
              </Anchor>
            </li>

            <li className='lg:my-2 sm:my-8 h2 fill-effect'>
              <Anchor className='no-underline' href='/about-us' onClick={closeMenu}>
                <TextHoverFill headerClassName='h2' text='About Us' />
              </Anchor>
            </li>

            <li className='lg:my-2 sm:my-8 h2 fill-effect'>
              <Anchor className='no-underline' href='/contact-us' onClick={closeMenu}>
                <TextHoverFill headerClassName='h2' text='Contact Us' />
              </Anchor>
            </li>

            {/* <li className='lg:my-2 sm:my-8 h2 fill-effect'>
              <Anchor className='no-underline' href='/' onClick={closeMenu}>
                <TextHoverFill headerClassName='h2' text='Blog' />
              </Anchor>
            </li> */}

          </ul>
        </nav>
        <div role='image' className='font-header text-onyx-black text-center select-none pointer-events-none absolute bottom-0 lg:left-0 sm:right-0' style={{ zIndex: -1, fontSize: '40vh', lineHeight: '35vh' }}>
          ON<br />YX
        </div>
      </animated.div>

      <div className='fixed lg:top-1/2 sm:top-0 right-0 z-50 lg:mr-8 sm:mr-2 py-3 transform lg:-translate-y-1/2 transition-opacity duration-300 hover:opacity-50'>
        <div role='button' className='' onClick={menuHandler}>
          <AnimatedMenuIcon className='w-10 sm:w-8' open={openMenu} />
        </div>
      </div>
    </>
  );
};