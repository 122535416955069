import * as React from 'react';
import cx from '../utilities/cx';

interface TextHoverFillProps {
	className?: string;
	text: string;
	children?: undefined;
	overrideHover?: boolean;
	headerClassName?: string;
	as?: keyof JSX.IntrinsicElements
}

const TextHoverFill: React.FC<TextHoverFillProps> = ({ className, headerClassName, text, overrideHover, as }) => {
	const [hover, setHover] = React.useState(false);
	const Elem = as ?? "h1"

	return (
		<div className={cx('relative', className)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
			<Elem className={cx('fill-effect hover:text-transparent', headerClassName)}>{text}</Elem>

			<div className={cx('absolute inset-0 transition-all duration-300 select-none clip-r', (overrideHover ?? hover) && 'unclip')}>
				<Elem className={cx('text-onyx-light-gray', headerClassName)}>{text}</Elem>
			</div>
		</div>
	);
};

export default TextHoverFill;