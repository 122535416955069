import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import Helmet from "react-helmet";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import { Menu } from "../components/Menu";
import OurLocation from "../components/OurLocation";
import ScrollBar from "../components/ScrollBar";
import StickyHeader from "../components/StickyHeader";
import config from "../utilities/config";

const TalkToUs: React.FC<RouteComponentProps> = () => {
	const [target, setTarget] = React.useState<HTMLElement | undefined>();

	const title = config.post_title;
	const metaDescription = config.site_description;
	const metaImage = config.meta_image;

	return (
		<>
			<Helmet>
				<title>Contact Us{config.title_separator}{title}</title>
				<meta name="description" content={metaDescription} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={metaDescription} />
				<meta property="og:image" content={metaImage} />
				<meta property="twitter:title" content={title} />
				<meta property="twitter:description" content={metaDescription} />
				<meta property="twitter:image" content={metaImage} />
			</Helmet>

			<StickyHeader />
			<ScrollBar boundary={target} />
			<Menu />

			<div className='main' ref={e => setTarget(e ?? undefined)}>
				<div className="content">
					<header className="w-full py-40 text-center">
						<h1>Contact Us</h1>
						<p>
							Interested in working together? Ok, don't be shy.<br />Just fill out the form below.
						</p>
					</header>

					<ContactForm className='max-w-lg mb-section' />

					<div className="lg:w-full flex flex-wrap sm:max-w-xs">
						<div className="lg:w-1/3 sm:w-full flex items-start">
							<img className="mr-6" src={require('../../assets/icon-phone.svg')} />
							<p className="leading-none">+65 6557 2825</p>
						</div>
						<div className="lg:w-1/3 sm:w-full flex items-start">
							<img className="mr-6" src={require('../../assets/icon-email.svg')} />
							<p className="leading-none">info @onyxisland.com</p>
						</div>
						<div className="lg:w-1/3 sm:w-full flex items-start">
							<img className="mr-6" src={require('../../assets/icon-location.svg')} />
							<div className="flex flex-col">
								<p className="leading-none">20 Kallang Ave</p>
								<p className="leading-none">#06-06B Pico Creative Centre</p>
								<p className="leading-none">Singapore 339411</p>
							</div>
						</div>
					</div>
				</div>
				<OurLocation />
			</div>

			<Footer />
		</>
	);
};

export default TalkToUs;