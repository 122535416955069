import * as React from 'react';
import { animated, config, interpolate, useSpring } from 'react-spring';
import cx from '../utilities/cx';
import useMinWidth from '../utilities/useMinWidth';
import Anchor from './Anchor';
import TextHoverFill from './TextHoverFill';
import TimeoutBar from './TimeoutBar';
import { navigate } from '@reach/router';

interface NextTileProps {
	className?: string;
	alignRight?: boolean;
	articleLink: string;
	title: string;
	imageUrl: string;
}

const calcXY = (e: React.MouseEvent<HTMLElement>) => {
	const info = e.currentTarget.getClientRects()[0];

	const width = info.x + info.width;
	const height = info.y + info.height;
	const x = e.clientX;
	const y = e.clientY;

	return { x: x - width / 2, y: y - height / 2 };
};

const translateLayer1 = (x: number, y: number) => `translate3d(${x / 20}px, ${y / 20}px, 0)`;
const translateLayer2 = (x: number, y: number) => `translate3d(${x / 15}px, ${y / 15}px, 0)`;

const NextTile: React.FC<NextTileProps> = ({ className, articleLink, alignRight, title, imageUrl }) => {
	const [{ x, y }, set] = useSpring<{ x: number, y: number; }>(() => ({ x: 0, y: 0, config: config.molasses }));
	const [active, setActive] = React.useState(false);
	const self = React.useRef<HTMLElement | null>(null);
	const isDesktop = useMinWidth(768);

	React.useEffect(() => {
		const listener = () => {
			if (self.current != null) {
				const selfInfo = self.current.getClientRects()[0];
				const center = window.innerHeight / 2;
				const selfTop = selfInfo.y;
				const selfBottom = selfInfo.y + selfInfo.height;

				setActive(selfTop < center && center < selfBottom);
			}
		};

		listener();

		window.addEventListener('scroll', listener);
		return () => window.removeEventListener('scroll', listener);
	}, []);

	function onComplete() {
		navigate(articleLink);
		window.scrollTo(0, 0);
	}

	return (
		<article
			ref={self}
			className={cx('relative flex items-center justify-center', className)}
			onMouseLeave={() => set({ x: 0, y: 0 })}
			onMouseMove={e => isDesktop ? set(calcXY(e)) : set({ x: 0, y: 0 })}
		>
			<animated.div className='lg:w-11/12 sm:w-full bg-onyx-black lg:ratio-21/9 sm:ratio-1/1 ' style={{ transform: interpolate([x, y], translateLayer2) }}>
				<img
					className={cx('w-full h-full absolute inset-0 shadow-2xl transition-opacity duration-300 object-cover object-center', active ? 'opacity-75' : 'opacity-25')}
					src={imageUrl}
				/>
				<div className='absolute inset-0'></div>
			</animated.div>
			<animated.header
				className='lg:max-w-1/3 sm:w-full absolute inset-y-0 inset-x-0 mx-auto sm:px-8 flex flex-col justify-center'
				style={{ transform: interpolate([x, y], translateLayer1) }}
			>
				<Anchor className='block' href={articleLink}>
					<TextHoverFill headerClassName='h2' text={title} overrideHover={active} />
				</Anchor>
				{isDesktop && (
					<>
						<p className='font-header text-center uppercase'>Next Project</p>
						<TimeoutBar running={active} onComplete={onComplete} />
					</>
				)}
			</animated.header>
		</article>
	);
};

export default NextTile;