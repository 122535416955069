import * as React from 'react';
import { animated, config, interpolate, useSpring } from 'react-spring';
import { AllArticleQuery_articles_tags } from '../../__generated__/AllArticleQuery';
import cx from '../utilities/cx';
import isSocial, { Tag } from '../utilities/isSocial';
import useMinWidth from '../utilities/useMinWidth';

interface ParallaxTileProps {
	title?: string;
	className?: string;
	imageClassName?: string;
	textClassName?: string;
	imageUrl: string;
	imageAlt?: string;
	overrideActive?: boolean;
	tags?: (AllArticleQuery_articles_tags | null)[] | null;
	children?: (active: boolean) => React.ReactNode;
}

const calcXY = (e: React.MouseEvent<HTMLElement>) => {
	const info = e.currentTarget.getClientRects()[0];

	const width = info.x + info.width;
	const height = info.y + info.height;
	const x = e.clientX;
	const y = e.clientY;

	return { x: x - width / 2, y: y - height / 2 };
};

const translateLayer1 = (x: number, y: number) => `translate3d(${x / 20}px, ${y / 20}px, 0)`;
const translateLayer2 = (x: number, y: number) => `translate3d(${x / 15}px, ${y / 15}px, 0)`;

const ParallaxTile = ({ title, tags, overrideActive, className, imageUrl, imageAlt, children, imageClassName, textClassName }: ParallaxTileProps): React.ReactElement | null => {
	const [{ x, y }, set] = useSpring<{ x: number, y: number; }>(() => ({ x: 0, y: 0, config: config.molasses }));
	const [active, setActive] = React.useState(false);
	const self = React.useRef<HTMLElement | null>(null);
	const isDesktop = useMinWidth(768);

	React.useEffect(() => {
		const listener = () => {
			if (self.current != null) {
				const selfInfo: DOMRect | undefined = self.current.getClientRects()[0];
				if (selfInfo != null) {
					const center = window.innerHeight / 2;
					const selfTop = selfInfo.y;
					const selfBottom = selfInfo.y + selfInfo.height;

					setActive(selfTop < center && center < selfBottom);
				}
			}
		};

		listener();

		window.addEventListener('scroll', listener);
		return () => window.removeEventListener('scroll', listener);
	}, []);

	return (
		<article
			ref={self}
			className={cx('relative flex items-center', className)}
			onMouseLeave={() => set({ x: 0, y: 0 })}
			onMouseMove={e => isDesktop ? set(calcXY(e)) : set({ x: 0, y: 0 })}
		>
			<animated.div className={cx('self-start bg-onyx-black', imageClassName)} style={{ transform: interpolate([x, y], translateLayer2) }}>
				<img
					className={cx('w-full h-full absolute inset-0 shadow-2xl transition-opacity duration-300 object-cover object-center', (overrideActive ?? active) ? 'opacity-50' : 'opacity-25')}
					src={imageUrl}
					alt={imageAlt}
				/>

				{/* Only show for social media related pages */}
				{tags && isSocial(tags, title) && (
					<img src={require("../../assets/HAA-rect-banner.png")} alt="Hashtag Award Winners" className={cx('absolute right-3 bottom-3 haa-badge', active ? 'opacity-100' : 'opacity-25')} />
				)}
			</animated.div>
			<animated.header
				className={cx('absolute inset-y-0 lg:px-8 sm:px-4 flex flex-col justify-center right-0', textClassName)}
				style={{ transform: interpolate([x, y], translateLayer1) }}
			>
				{children?.(overrideActive ?? active)}
			</animated.header>
		</article>
	);
};

export default ParallaxTile;