import { useQuery } from "@apollo/react-hooks";
import { RouteComponentProps } from "@reach/router";
import { gql } from "apollo-boost";
import * as React from "react";
import Helmet from "react-helmet";
import { animated, useSpring } from 'react-spring';
import { AllArticleQuery, AllArticleQuery_articles_tags } from "../../__generated__/AllArticleQuery";
import Anchor from "../components/Anchor";
import FilterList from "../components/FilterList";
import Footer from "../components/Footer";
import { Menu } from "../components/Menu";
import ParallaxTile from "../components/ParallaxTile";
import ScrollBar from "../components/ScrollBar";
import StickyHeader from "../components/StickyHeader";
import TextHoverFill from "../components/TextHoverFill";
import config from "../utilities/config";
import StrapiURLContext from "../utilities/strapiURL";
import useMinWidth from "../utilities/useMinWidth";
import { useEffect, useState } from 'react';

const ALL_ARTICLE = {
  articles: [{
      id: "12",
      title: "ZujuGP",
      heroImage: {
        url: "/uploads/img_zuju_subheader_bcf9053980.png",
        __typename: "UploadFile"
      },
      tags: [{
          id: "3",
          name: "Concept",
          __typename: "Tag"
        },
        {
          id: "4",
          name: "Social",
          __typename: "Tag"
        },
        {
          id: "11",
          name: "Works",
          __typename: "Tag"
        }
      ],
      slug: "ZujuGP",
      __typename: "Article"
    },
    {
      id: "9",
      title: "Cortina Site Revamp ",
      heroImage: {
        url: "/uploads/img_cortina_subheader_24999e7396.png",
        __typename: "UploadFile"
      },
      tags: [{
          id: "2",
          name: "Technology",
          __typename: "Tag"
        },
        {
          id: "7",
          name: "UI layouts",
          __typename: "Tag"
        },
        {
          id: "6",
          name: "User experience",
          __typename: "Tag"
        }
      ],
      slug: "cortina-site-revamp",
      __typename: "Article"
    },
    {
      id: "10",
      title: "Social Media Packages",
      heroImage: {
        url: "/uploads/casestudies_socialpost_masthead_c4d6c5db23.png",
        __typename: "UploadFile"
      },
      tags: [{
        id: "4",
        name: "Social",
        __typename: "Tag"
      }],
      slug: "social-media-packages",
      __typename: "Article"
    },
    {
      id: "8",
      title: "The Tatler Bar",
      heroImage: {
        url: "/uploads/b84bada4351a4891b95b5cc9f7a69239.png",
        __typename: "UploadFile"
      },
      tags: [{
        id: "10",
        name: "Ecommerce",
        __typename: "Tag"
      }],
      slug: "the-tatler-bar",
      __typename: "Article"
    },
    {
      id: "5",
      title: "CAAS",
      heroImage: {
        url: "/uploads/3c448cc7277e4142a7760776d76b5926.png",
        __typename: "UploadFile"
      },
      tags: [{
          id: "7",
          name: "UI layouts",
          __typename: "Tag"
        },
        {
          id: "6",
          name: "User experience",
          __typename: "Tag"
        }
      ],
      slug: "caas",
      __typename: "Article"
    },
    {
      id: "1",
      title: "Digital Income",
      heroImage: {
        url: "/uploads/93d1b96fc3c8471e98bab2b27d9cf33d.png",
        __typename: "UploadFile"
      },
      tags: [{
          id: "8",
          name: "Animation",
          __typename: "Tag"
        },
        {
          id: "3",
          name: "Concept",
          __typename: "Tag"
        },
        {
          id: "4",
          name: "Social",
          __typename: "Tag"
        }
      ],
      slug: "digital-income",
      __typename: "Article"
    },
    {
      id: "6",
      title: "Aviva",
      heroImage: {
        url: "/uploads/3694fdadb15647f9870b601e239cdf90.jpeg",
        __typename: "UploadFile"
      },
      tags: [{
          id: "2",
          name: "Technology",
          __typename: "Tag"
        },
        {
          id: "7",
          name: "UI layouts",
          __typename: "Tag"
        },
        {
          id: "6",
          name: "User experience",
          __typename: "Tag"
        }
      ],
      slug: "aviva",
      __typename: "Article"
    },
    {
      id: "7",
      title: "The Race to Beat Cancer",
      heroImage: {
        url: "/uploads/5336eea9074d46d4ae1daa0c5b04a2a9.png",
        __typename: "UploadFile"
      },
      tags: [{
          id: "9",
          name: "Campaign",
          __typename: "Tag"
        },
        {
          id: "3",
          name: "Concept",
          __typename: "Tag"
        },
        {
          id: "4",
          name: "Social",
          __typename: "Tag"
        }
      ],
      slug: "pcc",
      __typename: "Article"
    },
    {
      id: "3",
      title: "Singtel TV Go",
      heroImage: {
        url: "/uploads/5a4cb71401f14f1c872b7671f9a1d8a5.png",
        __typename: "UploadFile"
      },
      tags: [{
          id: "5",
          name: "Solution",
          __typename: "Tag"
        },
        {
          id: "2",
          name: "Technology",
          __typename: "Tag"
        },
        {
          id: "7",
          name: "UI layouts",
          __typename: "Tag"
        }
      ],
      slug: "singtel-tv-go",
      __typename: "Article"
    },
    {
      id: "4",
      title: "Intuit",
      heroImage: {
        url: "/uploads/93dd517ef30d41c49936e1f1f7612eb7.png",
        __typename: "UploadFile"
      },
      tags: [{
          id: "8",
          name: "Animation",
          __typename: "Tag"
        },
        {
          id: "9",
          name: "Campaign",
          __typename: "Tag"
        }
      ],
      slug: "intuit",
      __typename: "Article"
    },
    {
      id: "2",
      title: "Experian",
      heroImage: {
        url: "/uploads/bd2dd2640b4d48758996d6faf347b772.png",
        __typename: "UploadFile"
      },
      tags: [{
          id: "2",
          name: "Technology",
          __typename: "Tag"
        },
        {
          id: "7",
          name: "UI layouts",
          __typename: "Tag"
        },
        {
          id: "6",
          name: "User experience",
          __typename: "Tag"
        }
      ],
      slug: "experian",
      __typename: "Article"
    }
  ]
}

const BestOfOnyxPage: React.FC<RouteComponentProps> = () => {
  const data  = ALL_ARTICLE;
  const [contentElem, setTarget] = React.useState<HTMLElement | undefined>();
  const [activeTag, setActiveTag] = React.useState<string | null>(null);
  const [hoverActive, setHoverActive] = React.useState<string | null>(null);
  const [fade, setFade] = React.useState<boolean>(true);
  const strapiURL = React.useContext(StrapiURLContext);
  const isLg = useMinWidth(1024);

  const filteredArticles = React.useMemo(() => {
    const nullRemoved = data?.articles?.filter((x): x is NonNullable<typeof x> => x != null) ?? [];
    if (activeTag != null) {
      return nullRemoved.filter((article) => {
        const found = article.tags?.find((tag) => {
          return tag?.id === activeTag;
        });
        return found ? article : null;
      });
    } else {
      return nullRemoved;
    }
  }, [data?.articles, activeTag]);

  const handleTagClick = (tagId: string | null) => {
    setFade(true);
    setTimeout(() => {
      setActiveTag(tagId);
    }, 300);
  };

  const wrapperProp = useSpring({
    opacity: fade ? 0 : 1,
    onRest: () => {
      if (fade) setFade(false);
    }
  });

  const title = config.post_title;
  const metaDescription = config.site_description;
  const metaImage = config.meta_image;

  useEffect(() => {
    console.log('data = ',data)
  }, [data])

  return (
    <>
      <Helmet>
        <title>Case Studies{config.title_separator}{title}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content={metaImage} />
      </Helmet>

      <StickyHeader />
      <ScrollBar boundary={contentElem} />
      <Menu />
      <div ref={e => setTarget(e ?? undefined)} className='main flex items-center justify-center flex-col sm:px-8 lg:px-16 relative'>
        <div className='w-full max-w-6xl relative'>
          <div className='flex justify-center items-center flex-col'>
            <div className='w-full flex flex-col content-center justify-center text-center'>
              <h1 className='text-6xl uppercase py-20'>Case Studies</h1>
            </div>
          </div>

          <div className='flex flex-col'>
            <FilterList onClick={handleTagClick} />
            <animated.div className="flex-1 flex flex-wrap justify-center" style={wrapperProp}>
              {filteredArticles.map((item) => (
                <div className="sm:w-full lg:w-1/2 px-2 my-2" key={item.id} onMouseEnter={() => setHoverActive(item.id)} onMouseLeave={() => setHoverActive(null)}>
                  <Anchor href={`/case-study/${item?.slug}`}>
                    <ParallaxTile
                      title={item?.title}
                      overrideActive={isLg ? hoverActive === item.id : undefined}
                      className='mb-16'
                      imageClassName='sm:w-full lg:w-3/4 ratio-1/1'
                      textClassName='left-0'
                      imageUrl={`${strapiURL}${item?.heroImage?.url ?? ''}`}
                      tags={item?.tags}
                    >
                      {active => (
                        <div>
                          <TextHoverFill as="h2" headerClassName='lg:h2 lg:mb-3 sm:mb-3' overrideHover={active} text={item.title} />
                          {item.tags?.map(tag => (
                            <>
                              <a href='#' className='tag mr-3 mb-3'>{tag?.name.replace(" ", "\xa0")}</a>{" "}
                            </>
                          ))}
                          {/* <img src={require('../../assets/HAA-rect-banner.png')} alt="" /> */}
                        </div>
                      )}
                    </ParallaxTile>
                  </Anchor>
                </div>
              ))}
            </animated.div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BestOfOnyxPage;