import lottie from 'lottie-web';
import * as React from 'react';

const Animation: React.FC<{ className?: string; animationData: any; loop?: boolean; }> = ({ className, animationData, loop = false }) => {
	const ref = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (ref.current != null) {
			const instance = lottie.loadAnimation({
				container: ref.current,
				animationData,
				renderer: 'svg',
				loop,
			});

			setTimeout(() => {
				instance.play();
			}, 1500)
		}
	}, []);

	return (
		<div className={className} ref={ref}></div>
	);
};

export default Animation;