import { createContext, useContext } from 'react';
import cx from '../utilities/cx';
import ReactMarkdown = require('react-markdown');

const TextNoBottomMarginCtx = createContext(false);
const HeaderNormalAlignCtx = createContext(false);

type TextCenterHeadingProps = React.ComponentProps<'h1'> & {
	level: number;
};

function TextCenterHeading({ level, ...props }: TextCenterHeadingProps) {
	const textNoBottomMargin = useContext(TextNoBottomMarginCtx);
	const headerNormalAlign = useContext(HeaderNormalAlignCtx);

	const Tag = level == 6 ? 'h6' :
		level == 5 ? 'h5' :
			level == 4 ? 'h4' :
				level == 3 ? 'h3' :
					level == 2 ? 'h2' :
						'h1';

	return <Tag {...props} className={cx(headerNormalAlign || 'sm:text-center', textNoBottomMargin && 'mb-0', props.className)} />;
}

function UnderlineLink(props: React.ComponentProps<'a'>) {
	const textNoBottomMargin = useContext(TextNoBottomMarginCtx);

	return (
		<a {...props} className={cx('text-link', textNoBottomMargin && 'mb-0', props.className)} />
	);
}

function Paragraph(props: React.ComponentProps<'p'>) {
	const textNoBottomMargin = useContext(TextNoBottomMarginCtx);

	return (
		<p {...props} className={cx(textNoBottomMargin && 'mb-0', props.className)} />
	);
}

type MarkdownProps = {
	source: string;
	textNoBottomMargin?: boolean;
	headerNormalAlign?: boolean;
};

export default function Markdown({ source, textNoBottomMargin, headerNormalAlign }: MarkdownProps) {
	return (
		<HeaderNormalAlignCtx.Provider value={headerNormalAlign ?? false}>
			<TextNoBottomMarginCtx.Provider value={textNoBottomMargin ?? false}>
				<ReactMarkdown source={source} renderers={{ link: UnderlineLink, heading: TextCenterHeading, paragraph: Paragraph }} />
			</TextNoBottomMarginCtx.Provider>
		</HeaderNormalAlignCtx.Provider>
	);
}