import * as React from 'react';
import cx from '../utilities/cx';

const mapEmbedURL = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4777.842847335563!2d103.87646981924739!3d1.3155262313614868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19cc0a049645%3A0x14a2bf61efbac275!2sOnyx%20Island%20Pte.%20Ltd.!5e0!3m2!1sen!2sid!4v1582762809428!5m2!1sen!2sid';

const OurLocation = ({ className = '' }) => (
  <div className={cx('w-full flex justify-center', className)} style={{ filter: 'grayscale(100%) invert(100%)' }}>
    <iframe src={mapEmbedURL} className='w-full' style={{ border: 0, height: 450 }} />
  </div>
);

export default OurLocation;