import * as React from 'react';
import { animated, useTransition } from 'react-spring';
import Anchor from './Anchor';
const placeholder = require('../../assets/placeholder.png');

type StickyHeaderProps = {
  hidden?: boolean;
};

const StickyHeader: React.FC<StickyHeaderProps> = ({ hidden = false }) => {
  const anim = useTransition(!hidden, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {anim.map(({ item, key, props }) => (
        item && (
          <animated.div key={key} className='fixed inset-x-0 top-0 z-40 p-3 flex items-center justify-between' style={props}>
            <Anchor href='/'>
              <img className='h-10 transition-opacity duration-300 hover:opacity-50' src={require('../../assets/icon-onyxisland-white.svg')} alt='Onyx Island Logo' />
            </Anchor>
            <a className='sm:hidden' target='blank' href='https://goo.gl/maps/pwy2e6dsdM4AUWDY6'>
              <LocationSVG />
            </a>
          </animated.div>
        )
      ))}
    </>
  );
};

const LocationSVG: React.FC = () => (
  <svg width='14px' height='18px' viewBox='0 0 14 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g fill='none' stroke='currentColor'>
      <path d='M12.757,6.6283 C12.757,10.0633 8.445,15.0673 7.156,16.4873 C6.96,16.7033 6.627,16.7063 6.426,16.4953 C5.077,15.0763 0.5,10.0123 0.5,6.6283 C0.5,3.2433 3.243,0.5003 6.628,0.5003 C10.013,0.5003 12.757,3.2433 12.757,6.6283 Z'></path>
      <path d='M9.8029,6.9349 C9.8029,8.6879 8.3809,10.1089 6.6279,10.1089 C4.8749,10.1089 3.4539,8.6879 3.4539,6.9349 C3.4539,5.1819 4.8749,3.7609 6.6279,3.7609 C8.3809,3.7609 9.8029,5.1819 9.8029,6.9349 Z'></path>
    </g>
  </svg>
);

export default StickyHeader;