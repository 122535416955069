import { Link } from '@reach/router';
import * as React from 'react';

const goToTop = () => {
	window.scrollTo(0, 0);
};

function gtag() { ((window as any).dataLayer as any[]).push(arguments); }

type AnchorProps = Omit<React.ComponentPropsWithoutRef<'a'>, 'href'> & {
	href: string;
};


const Anchor: React.FC<AnchorProps> = ({ href, ...props }) => (
	<Link {...props} to={href} onClick={e => {
		props.onClick?.(e);
		if (e.isDefaultPrevented() == false) {
			// @ts-ignore
			gtag('config', ((window as any).gtagID as string), { page_path: href });
			goToTop();
		}
	}} />
);

export default Anchor;