import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import Helmet from "react-helmet";
import Anchor from "../components/Anchor";
import AnimatedLogo from "../components/AnimatedLogo";
import AnimatedLogoText from "../components/AnimatedLogoText";
import ContactForm from "../components/ContactForm";
import FillButton from "../components/FillButton";
import Footer from "../components/Footer";
import GoToTopButton from "../components/GoToTopButton";
import { Menu } from "../components/Menu";
import ParallaxTile from "../components/ParallaxTile";
import ScrollBar from "../components/ScrollBar";
import StickyHeader from "../components/StickyHeader";
import TextHoverFill from "../components/TextHoverFill";
import config from "../utilities/config";
import { animated, interpolate, useSpring } from 'react-spring';

const IndexPage: React.FC<RouteComponentProps> = () => {
	const [contentElem, setContentElem] = React.useState<HTMLElement | null>(null);
	const [bannerElem, setBannerElem] = React.useState<HTMLElement | null>(null);
	const [showHeader, setShowHeader] = React.useState(false);

	React.useEffect(() => {
		const handler = () => {
			if (bannerElem != null) {
				const scrollY = window.pageYOffset;
				const bannerElemInfo = bannerElem.getClientRects()[0];
				const boundary = bannerElemInfo.y + bannerElemInfo.height;

				setShowHeader(scrollY >= boundary);
			}
		};

		window.addEventListener('scroll', handler);
		return () => window.removeEventListener('scroll', handler);
	}, [contentElem]);

	const title = `${config.default_pre_title}${config.title_separator}${config.post_title}`;
	const metaDescription = config.site_description;
	const metaImage = config.meta_image;

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={metaDescription} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={metaDescription} />
				<meta property="og:image" content={metaImage} />
				<meta property="twitter:title" content={title} />
				<meta property="twitter:description" content={metaDescription} />
				<meta property="twitter:image" content={metaImage} />
			</Helmet>

			<StickyHeader hidden={!showHeader} />
			<ScrollBar boundary={contentElem} />
			<Menu />

			<div ref={e => setContentElem(e)} className='main'>
				<div className='content'>
					<header ref={e => setBannerElem(e)} className='relative w-full min-h-screen mb-section'>
						<AnimatedLogo />

						<div className='absolute inset-0 flex mx-auto flex-col content-center justify-center text-center content content-width-only'>
							<p>Welcome to</p>
							<img alt='Onyx Island' src={require('../../assets/textlogo-onyxisland.svg')} style={{ maxHeight: '95px' }} />
							<AnimatedLogoText className='self-center w-2/3 max-w-83 transform -translate-y-full' />
							<div className='absolute bottom-0 inset-x-0 mx-auto mb-20 h-12 bg-onyx-green animation-bounce animation-duration-2 infinite' style={{ width: '1px' }} />
							<div className='absolute bottom-0 inset-x-0 mx-auto mb-6'>
								Explore our works
							</div>
						</div>
					</header>

					{/* <FeaturedArticles className='mb-section' /> */}


					<section className='w-full mb-section'>
						<article className='relative flex items-center justify-center mb-section'>
							<div className='lg:w-11/12 sm:w-full bg-onyx-black lg:ratio-21/9 sm:ratio-1/1 ' style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
								<img className='w-full h-full absolute inset-0 shadow-2xl transition-opacity duration-300 object-cover object-center opacity-100' src={require('../../assets/casestudies_socialpost_masthead.png')} />
								<div className='absolute inset-0 sm:bg-gradient-black-l z-10 lg:bg-gradient-black-l'></div>
								<img src={require('../../assets/HAA-rect-banner.png')} alt='Hashtag Award Winners' className='absolute right-3 bottom-3 haa-badge opacity-100' />
							</div>
							<header className='lg:max-w-1/2 sm:w-full absolute inset-y-0 sm:inset-x-0 sm:px-8 flex flex-col justify-center lg:left-0' style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
								<a className='block' href='/case-study/social-media-packages'>
									<div className='relative'>
										<h1 className='fill-effect hover:text-transparent'>Social Media Packages</h1>
										<div className='absolute inset-0 transition-all duration-300 select-none clip-r unclip'>
											<h1 className='text-onyx-light-gray'>Social Media Packages</h1>
										</div>
									</div>
								</a>
								<p className='text-onyx-light-gray sm:hidden'>Your brand can be a content hub, social listener, experience creator and more.</p>
								<ul>
									<li className='inline mr-3 mb-3'><a href='#' className='tag no-underline'>Social</a></li>
								</ul>
							</header>
						</article>

						<article className='relative flex items-center justify-center'>
							<div className='lg:w-11/12 sm:w-full bg-onyx-black lg:ratio-21/9 sm:ratio-1/1' style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
								<img className='w-full h-full absolute inset-0 shadow-2xl transition-opacity duration-300 object-cover object-center opacity-100' src={require('../../assets/casestudies_socialpost_masthead.png')} />
								<div className='absolute inset-0 sm:bg-gradient-black-l z-10 lg:bg-gradient-black-r'></div>
							</div>
							<header className='lg:max-w-1/2 sm:w-full absolute inset-y-0 sm:inset-x-0 sm:px-8 flex flex-col justify-center lg:right-0' style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
								<a className='block' href='/case-study/the-tatler-bar'>
									<div className='relative'>
										<h1 className='fill-effect hover:text-transparent'>The Tatler Bar</h1>
										<div className='absolute inset-0 transition-all duration-300 select-none clip-r unclip'>
											<h1 className='text-onyx-light-gray'>The Tatler Bar</h1>
										</div>
									</div>
								</a>
								<p className='text-onyx-light-gray sm:hidden'>An ecommerce solution built to meet the rising demand for premium tipples coupled with the boom of pandemic-inspired online shopping.</p>
								<ul>
									<li className='inline mr-3 mb-3'><a href='#' className='tag no-underline'>Ecommerce</a></li>
								</ul>
							</header>
						</article>
					</section>


					<section className='w-full flex justify-center mb-section'>
						<ParallaxTile className='lg:w-3/4 sm:w-full' imageClassName='w-2/3 lg:ratio-1/1 sm:ratio-2/3' textClassName='w-1/2' imageUrl={require('../../assets/img-aboutus.png')}>
							{active => (
								<>
									<Anchor href='/about-us'>
										<TextHoverFill overrideHover={active} text={'About\n Us'} />
									</Anchor>
									<p className='sm:hidden'>
									Onyx Island is a Digital Experience Agency specialising in marketing technology, social media marketing and creative services. We are a Diamond HubSpot Solutions Partner with strong expertise in HubSpot CRM onboarding, implementation, integration and CMS development.
									</p>
									<Anchor href='/about-us'>
										<FillButton wrapperClassName='w-full' className='primary w-full min-w-full'>
											Find Out More
										</FillButton>
									</Anchor>
								</>
							)}
						</ParallaxTile>
					</section>


					<section className='w-full max-w-screen-lg mb-section'>
						<header>
							<h3 className='text-center'>OUR SERVICES</h3>
						</header>
						<div className='flex flex-wrap w-full'>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/digital-transformation-and-consultancy.svg')} />
								</div>
								<p className='leading-6'>Digital transformation and consultancy</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/strategy-and-planning.svg')} />
								</div>
								<p className='leading-6'>Strategy and planning</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/ui-ux-design.svg')} />
								</div>
								<p className='leading-6'>User experience / User interface (UX/UI) design</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/information-architecture.svg')} />
								</div>
								<p className='leading-6'>Information architecture (IA)</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/digital-and-social-media-marketing.svg')} />
								</div>
								<p className='leading-6'>Digital and social media marketing</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/creative-ideation.svg')} />
								</div>
								<p className='leading-6'>Creative ideation</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/content-creation-and-production.svg')} />
								</div>
								<p className='leading-6'>Content creation and production</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/crm-implementation-and-integration.svg')} />
								</div>
								<p className='leading-6'>CRM implementation and integration</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/web-operations-and-maintenance-support.svg')} />
								</div>
								<p className='leading-6'>Web operations and maintenance support</p>
							</div>
							<div className='text-center lg:w-1/5 sm:w-1/2 my-6 px-3'>
								<div className='inline-block w-24 mb-6 ratio-1/1 relative'>				
									<img className='absolute inset-0 h-full w-full object-contain object-center' src={require('../../assets/services/data-analytics-and-reporting.svg')} />
								</div>
								<p className='leading-6'>Data analytics and reporting</p>
							</div>
						</div>
					</section>


					{/* <OurServices className='mb-section' /> */}

					<section className='w-full flex flex-col items-center'>
						<h3 className='text-center'>Contact Us</h3>
						<ContactForm className='max-w-lg mb-section' />
					</section>
				</div>
			</div>
			<GoToTopButton boundary={contentElem} />
			<Footer />
		</>
	);
};

export default IndexPage;
