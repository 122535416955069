import * as React from 'react';
import cx from '../utilities/cx';

interface TimeoutBarProps {
	duration?: number;
	running: boolean;
	onComplete: () => void;
}

const TimeoutBar: React.FC<TimeoutBarProps> = ({ duration = 5000, running, onComplete }) => {
	const [internal, setInternal] = React.useState({ duration, running: false });

	React.useEffect(() => {
		setInternal({ duration, running });
	}, [running]);

	return (
		<div className='bg-onyx-dark-gray h-1 flex'>
			<div
				className={cx('bg-onyx-green w-0 transition-all ease-linear', internal.running && 'w-full')}
				style={{ transitionDuration: `${internal.running ? internal.duration : 0}ms` }}
				onTransitionEnd={() => setTimeout(onComplete, 0)}
			/>
		</div>
	);
};

export default TimeoutBar;