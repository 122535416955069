import * as React from 'react';
import cx from '../utilities/cx';
import Helmet from 'react-helmet';

const GoToTopButton: React.FC<{ boundary?: HTMLElement | null; }> = ({ boundary }) => {
	const [show, setShow] = React.useState(false);
	const [bottom, setBottom] = React.useState(0);

	React.useEffect(() => {
		const scrollListener = () => {
			if (document.documentElement.scrollTop > 50) {
				setShow(true);
			} else {
				setShow(false);
			}

			const footer = document.getElementById("footer");
			if (footer) {
				const footerRect = footer.getClientRects()[0];

				const footerInView = footerRect.y <= window.innerHeight;

				if (footerInView) {
					setBottom(footerRect.height);
				} else {
					setBottom(0);
				}
			}
		};

		scrollListener();

		window.addEventListener("scroll", scrollListener, { passive: true });
		window.addEventListener("resize", scrollListener, { passive: true });
		return () => {
			window.removeEventListener("scroll", scrollListener);
			window.removeEventListener("reize", scrollListener);
		};
	}, [boundary]);

	const onClick = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	};

	return (
		<>
			<Helmet>
				<style>{`div#hubspot-messages-iframe-container{position:${bottom > 0 ? "absolute" : "fixed"} !important;bottom:${bottom.toFixed(2)}px !important;z-index:40;}`}</style>
			</Helmet>
			<div className={cx(bottom > 0 ? "absolute" : "fixed", 'right-0 bottom-0 pr-24 pb-3 transition-opacity duration-300', show || 'opacity-0')} style={{ bottom: `${bottom}px` }}>
				<div role='button' className='p-8 rounded-full transition-colors duration-300 bg-onyx-mid-gray hover:bg-onyx-green relative' onClick={onClick}>
					<img className='absolute inset-0 m-auto' src={require('../../assets/icon-back to top.svg')} />
				</div>
			</div>
		</>
	);
};

export default GoToTopButton;