import * as React from 'react';
import lottie from 'lottie-web';

const AnimatedLogo: React.FC = () => {
	const container = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (container.current != null) {
			runAnimationIn(container.current);
		}
	}, []);

	return (
		<div className='absolute inset-0 overflow-x-hidden'>
			<div ref={container} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' style={{ width: '1152px', height: '648px', WebkitMaskImage: "-webkit-radial-gradient(rgba(0,0,0,1) 50%, rgba(0,0,0,0) 70%)" }}></div>
		</div >
	);
};

async function runAnimationIn(container: HTMLElement) {
	const data1 = require('../../assets/onyx logo/part1.json');
	const data2 = require('../../assets/onyx logo/part2.json');

	const animation1 = lottie.loadAnimation({
		container,
		animationData: data1,
		renderer: 'svg',
		loop: false,
		autoplay: false,
		rendererSettings: {
		}
	});
	const endOfAnimation1 = new Promise(ok => animation1.addEventListener('complete', ok));


	await new Promise(ok => setTimeout(ok, 1000));
	animation1.play();

	await endOfAnimation1;
	// await new Promise(ok => setTimeout(ok, 300));

	// animation1.destroy();
	// const animation2 = lottie.loadAnimation({
	// 	container,
	// 	animationData: data2,
	// 	renderer: 'svg',
	// 	loop: true,
	// 	autoplay: false,
	// 	rendererSettings: {
	// 	}
	// });

	// animation2.play();
}

export default AnimatedLogo;