import * as React from 'react';
import { createPortal } from 'react-dom';
import cx from '../utilities/cx';
import FillButton from './FillButton';
import GlueDropdown from './GlueDropdown';

const hsID = "hs-martech";

type Services_MarTechFormProps = {
	className?: string;
};

export default function Services_MarTechForm({ className }: Services_MarTechFormProps) {
	const hsDivRef = React.useRef<HTMLDivElement>(null);
	const [hsSelect, setHsSelect] = React.useState<[HTMLSelectElement, HTMLDivElement][]>([]);
	const [hasForm, setHasForm] = React.useState(false);

	React.useEffect(() => {
		const observer = new MutationObserver(() => {
			setHasForm(hsDivRef.current?.querySelector('form') != null);
		});

		observer.observe(hsDivRef.current!, {
			childList: true,
			subtree: true
		});

		return () => observer.disconnect();
	}, []);

	React.useEffect(() => {
		const id = window.setInterval(() => {
			if (hsDivRef.current?.querySelector('form') != null) {
				window.clearInterval(id);
				hsDivRef.current.querySelector('input[type=submit]')?.classList.add('hidden');

				hsDivRef.current.querySelectorAll('select').forEach(elem => {
					const newDiv = document.createElement('div');
					elem.parentNode?.insertBefore(newDiv, elem);
					setHsSelect(ary => [...ary, [elem, newDiv]]);
				});
				hsDivRef.current.querySelectorAll('textarea').forEach(elem => {
					elem.setAttribute('rows', '6');
				});
				// @ts-ignore
			} else if (window.hbspt != null) {
				// @ts-ignore
				window.hbspt.forms.create({
					portalId: "4390309",
					formId: "3c86d127-2f06-439f-a41c-140124853551",
					target: '#' + hsID,
				});
			}
		}, 500);

		return () => window.clearInterval(id);
	}, []);

	return (
		<div className={cx('w-full', className)}>
			<div ref={hsDivRef} id={hsID} className='hbspt-form' />

			{hsSelect.map(([select, portal]) => (
				createPortal(<GlueDropdown selectElem={select} />, portal, select.id)
			))}

			{hasForm && (
				<div className='w-full flex flex-col items-center'>
					<FillButton wrapperClassName='mx-auto' className='primary' value='submit' onClick={() => hsDivRef.current?.querySelector('form')?.submit()}>
						Send
					</FillButton>
				</div>
			)}
		</div>
	);
};